<template>
    <div class="library">
        <div class="desctop">
        <img src="@/assets/images/libraryback.jpg" class="mainimg"/>
    </div>
    <div class="mobile">
        <img src="@/assets/images/librarybackmobile.jpg" class="mainimg1"/>
    </div>
        <div class="library__models">
            <p @click="showModelInfo(1)" :class="{active: currentModel === 1}">H9</p>
            <p @click="showModelInfo(2)" :class="{active: currentModel === 2}">E-HS9</p>
            <p @click="showModelInfo(3)" :class="{active: currentModel === 3}">HS5</p>
        </div>
        <div v-if="currentModel === 1" class="library__h9">
            <p class="title">HONGQI ФОТОГРАФИИ</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in h9images" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/h9/'+ item.img +'.jpg') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                            <img src="@/assets/icons/find.svg"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <p class="title">HONGQI ВИДЕО</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in h9videos" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/h9/'+ item.img +'.jpg') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                            <img src="@/assets/icons/play.svg" class="play"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <p class="title">HONGQI ДАННЫЕ</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in h9content" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/h9/'+ item.img +'.jpg') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="currentModel === 2" class="library__ehs9">
            <p class="title">HONGQI ФОТОГРАФИИ</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in ehsImages" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/ehs/'+ item.img +'.png') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                            <img src="@/assets/icons/find.svg"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <p class="title">HONGQI ВИДЕО</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in ehsVideos" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/ehs/'+ item.img +'.png') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                            <img src="@/assets/icons/play.svg" class="play"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <p class="title">HONGQI ДАННЫЕ</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in ehsContent" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/ehs/'+ item.img +'.png') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="currentModel === 3" class="library__hs5">
            <p class="title">HONGQI ФОТОГРАФИИ</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in hs5images" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/hs5/'+ item.img +'.jpg') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                            <img src="@/assets/icons/find.svg"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <p class="title">HONGQI ВИДЕО</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in hs5videos" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/hs5/'+ item.img +'.jpg') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                            <img src="@/assets/icons/play.svg" class="play"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <p class="title">HONGQI ДАННЫЕ</p>
            <div class="photos container">
                <div class="photos__card" v-for="item in hs5content" :key="item">
                    <div class="photos__card__main" :style="{ backgroundImage: 'url(' + require('@/assets/images/hs5/'+ item.img +'.jpg') + ')' }">
                        <div class="photos__card__main__content">
                            {{ item.name }}
                            <div>
                            <img src="@/assets/icons/download.svg"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            currentModel: 1,
            h9images: [
                    {
                        name: "H9_img01",
                        img: "1"
                    },
                    {
                        name: "H9_img02",
                        img: "2"
                    },
                    {
                        name: "H9_img03",
                        img: "3"
                    },
                    {
                        name: "H9_img04",
                        img: "4"
                    },
                    {
                        name: "H9_img05",
                        img: "5"
                    },
                    {
                        name: "H9_img05",
                        img: "1"
                    },
                ],
            h9videos: [
                    {
                        name: "H9_noble gene",
                        img: "1"
                    },
                    {
                        name: "H9_ powerful heart",
                        img: "2"
                    },
                    {
                        name: "H9_most brain",
                        img: "3"
                    },
                    {
                        name: "H9_super high value",
                        img: "4"
                    },
                    {
                        name: "H9_luxury cabin",
                        img: "5"
                    },
                    {
                        name: "H9_hard bone5",
                        img: "1"
                    },
            ],
            h9content: [
                    {
                        name: "H9_Catalog3",
                        img: "1"
                    },
                    {
                        name: "H9_Catalog2",
                        img: "2"
                    },
                    {
                        name: "H9_Catalog",
                        img: "3"
                    },
            ],
            ehsImages: [
                    {
                        name: "E-HS9img03",
                        img: "1"
                    },
                    {
                        name: "E-HS9img02",
                        img: "2"
                    },
                    {
                        name: "E-HS9img01",
                        img: "3"
                    },
            ],
            ehsVideos: [
                    {
                        name: "E-HS9_video01",
                        img: "1"
                    },
            ],
            ehsContent: [
                    {
                        name: "E-HS9-Repair Manual",
                        img: "1"
                    },
                    {
                        name: "E-HS9-Owner's Manual",
                        img: "2"
                    },
                    {
                        name: "E-HS9- Electrical Wiring Diagram",
                        img: "3"
                    },{
                        name: "E-HS9-Maintenance Manual",
                        img: "2"
                    },
                    {
                        name: "HONGQI_E-HS9 EUR_Android Auto Using",
                        img: "3"
                    },
            ],
            hs5images:[
            {
                        name: "HS5_img09",
                        img: "1"
                    },
                    {
                        name: "HS5_img08",
                        img: "2"
                    },
                    {
                        name: "HS5_img07",
                        img: "3"
                    },
                    {
                        name: "HS5_img02",
                        img: "4"
                    },
                    {
                        name: "HS5_img01",
                        img: "5"
                    },
                    {
                        name: "HS5_img04",
                        img: "6"
                    },
            ],
            hs5videos: [
                    {
                        name: "HS5_video01",
                        img: "1"
                    },
            ],
            hs5content: [
                    {
                        name: "HS5-Catalog3",
                        img: "3"
                    },
                    {
                        name: "HS5-Catalog2",
                        img: "4"
                    },
                    {
                        name: "HS5-Catalog1",
                        img: "5"
                    },
            ]
        }
    },
    methods: {
    showModelInfo(value) {
      this.currentModel = value;
    },
  },
}
</script>
<style lang="scss" scoped>
.mainimg{
          padding-top: 120px;
          width: 100%;
          height: auto;
          @media(max-width:1710px){
              padding-top: 80px;
        }
        @media(max-width:1400px){
              padding-top: 70px;
        }
        }
        .mainimg1{
            padding-top: 75px;
            width: 100%;
            object-fit: cover;
        }
.active{
    color: black;
    font-weight: 600;
}
            .title{
                text-align: center;
                font-size: 45px;
                @media(max-width:867px){
                    font-size: 20px;
                }
            }
            .photos{
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
                padding-left: 50px;
                @media(max-width:867px){
                    flex-direction: column;
                    gap: 20px;
                }
                &__card{
                    width: 30.5%;
                    height: 350px;
                    background-color: rgba(0,0,0,.4) !important;
                    @media(max-width:1400px){
                        width: 30%;
                    }
                    @media(max-width:867px){
                        width: 100%;
                        object-fit: cover;
                    }
                    @media(max-width:767px){
                        width: 100%;
                        object-fit: cover;
                        height: 300px;
                    }
                    &__main{
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        z-index: -1;
                        @media(max-width:867px){
                        width: 100%;
                        object-fit: cover;
                        background-size: 100% 120%;
                        background-repeat: no-repeat;

                    }
                        @media(max-width:767px){
                        width: 100%;
                        height: 300px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;

                    }
                        &__content{
                            display: flex;
                            justify-content: space-between;
                            padding: 20px;
                            font-size: 23px;
                            align-items: center;
                            background-color: rgba(247, 238, 238, 0.582);
                            div{
                                display: flex;
                                gap: 10px;
                                img{
                                    width: 33px;
                                    height: 33px;
                                }
                            }
                        }
                    }
                }
            }
    .library{
        display: flex;
        flex-direction: column;
        gap: 70px;
        @media(max-width:867px){
            gap: 20px;
        }
        &__models{
            display: flex;
            gap: 180px; 
            justify-content: center;
            font-size: 30px;
            color: #a2a2a2;
            @media(max-width:867px){
            gap: 20px;
            font-size: 20px;
        }

        }
        &__h9{
            display: flex;
            flex-direction: column;
            gap: 70px;
            padding-top: 30px;
            padding-bottom: 30px;
            @media(max-width:867px){
            gap: 20px;
        }
            
        }
        &__ehs9{
            display: flex;
            flex-direction: column;
            gap: 70px;
            padding-top: 30px;
            padding-bottom: 30px; 
            @media(max-width:867px){
            gap: 20px;
        }
        }
        &__hs5{
            display: flex;
            flex-direction: column;
            gap: 70px;
            padding-top: 30px;
            padding-bottom: 30px; 
            @media(max-width:867px){
            gap: 20px;
        }
        }
    }
</style>