<template>
    <div class="line"></div>
    <div class="footer">
        <div class="footer__top">
            <img src="@/assets/images/footerlogo.png"/>
            <div class="footer__top__info">
                <div class="footer__top__info__1">
                    <p>ОСТАВАЙТЕСЬ НА СВЯЗИ</p>
                    <div class="line1"></div>
                </div>
                <p class="footer__top__info__2">Получайте последние новости, специальные предложения и эксклюзивные предложения.</p>
                <div class="footer__top__info__3">
                    <input v-model="UserData.email" placeholder="Ваш электронный адрес" type="text"/>
                    <button @click="sendData">Подписаться</button>
                </div>
                <div class="footer__top__info__4">
                    <input v-model="UserData.agreement" type="checkbox" class="checkbox"/>
                    <p>Я согласен с политикой конфиденциальности</p>
                </div>
            </div>
        </div>
        <div class="footer__botbackgound">
            <div class="footer__bot">
                <div class="footer__bot__1">
                    <p>Заявление о конфиденциальности</p>
                    <p>Настройки файлов cookie</p>
                    <router-link to="/contactus" style="color: inherit; text-decoration: none;"><p>Связаться с нами</p></router-link>
                </div>
                <p>Hongqi - китайский премиум-бренд автомобилей.</p>
                <p>ТОО "ALLUR PREMIUM"  БИН 230340001878</p>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
export default {
    data:() => ({
        UserData:{
            email: null,
            agreement: false,
        }
    }),
    methods:{
        sendData() {
            const article = {
                Email: this.UserData.email,
                Agreement: this.UserData.agreement,
                Date: Date()
            };
            axios
                .post("https://sheetdb.io/api/v1/098p87j5j1ygf?sheet=Footer", article)
                .then((response) => {
                    if (response.status === 201) {
                        alert('Успешно подписались')
                    } else {
                        console.log(response)
                    }
                })
                .catch((e) => {

                    console.log(e)
                })

        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
}
.line1{
    border: 0.5px solid black;
}
.footer{
    display: flex;
    flex-direction: column;
    gap: 70px;
    &__top{
        padding: 60px 250px;
        display: flex;
        justify-content: space-between;
        @media(max-width:867px){
                padding: 20px;
        }
        img{
            width: 130px;
            height: 130px;
            @media(max-width:867px){
                display: none;
            }
        }
        &__info{
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media(max-width:867px){
                width: 100%;
            }
            &__1{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            &__2{
                @media(max-width:867px){
                    font-size: 10px;
                }
            }
            &__3{
                display: flex;
                gap: 30px;
                padding-top: 28px;
                @media(max-width:867px){
                      gap: 10px;
                    }
                input{
                    padding: 10px;
                    border-radius: 40px;
                    background-color: #eee;
                    border: 1px solid #b8b8b8;
                    border-radius: 0.9vw;
                    font-size: .81em;
                    outline: none;
                    @media(max-width:867px){
                        padding: 5px 20px;
                        font-size: 12px;
                        border-radius: 50px;
                    }
                }
                button{
                    background-color: #42373c;
                    cursor: pointer;
                    border-radius: 40px;
                    color: #fff;
                    font-size: 1vw;
                    padding: 10px 20px;
                    border: none;
                    transition: all 0.5s ease;
                    @media(max-width:867px){
                        font-size: 12px;
                        padding: 5px 15px;
                    }
                    &:hover{
                        background: red;

                    }
                }
            }
            &__4{
                display: flex;
                gap: 15px;
                align-items: center;
                padding-top: 10px;
                @media(max-width:867px){
                        font-size: 12px;
                    }
                .checkbox{
                    width: 20px;
                    height: 20px;
                    @media(max-width:867px){
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
        &__botbackgound{
            background: #636363;
        }
        &__bot{
            color: #FFF;
            padding: 20px;
            opacity: .86;
            display: flex;
            flex-direction: column;
            font-size: 10px;
            gap: 20px;

            &__1{
                display: flex;
                gap: 40px;
                justify-content: center;
                font-size: 20px;
                @media(max-width:867px){
                flex-direction: column;
                font-size: 15px;
                gap: 20px;
            }
            }
            text-align: center;
        }
}
</style>