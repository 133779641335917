<template>
    <div class="callbackbackground" @click="$store.state.openCallBack = false">
        <div class="callback " @click.stop>
            <img src="@/assets/icons/logo.svg" />
            <div class="callback__title">
                <p>Давайте мы Вам позвоним</p>
            </div>
            <div class="callback__sub">
                <p>Оставьте свой номер, и мы перезвоним вам</p>
            </div>
            <div class="callback__number">
                <p>Ваш номер телефона*</p>
                <input v-model="v$.phone.$model"  v-mask="'+7 (###) ###-##-##'" placeholder="+7 (000) 000-00-00" @input="validateFields">
                <template v-for="(error) of v$.phone.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
                </template>
            </div>
            <div class="callback__number">
                <p>Имя*</p>
                <input v-model="v$.name.$model" placeholder="Введите свое имя" @input="validateFields"/>
                <template v-for="(error) of v$.name.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
                </template>
            </div>
            <select v-model="v$.cityData.$model" @input="validateFields">
              <option value="" disabled selected>Выберите город:</option>
              <option v-for="(item, idx) in city" :key="idx" :value="item">
                {{ item.name }}
              </option>
            </select>
            <template v-for="(error) of v$.cityData.$errors" :key="error">
                  <p class="errorValid">{{ error.$message }}</p>
                </template>
            <button @click="sendData" :disabled="v$.$invalid" >
                <div v-if="isLoading">
                    <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            <div v-else class="callback__call">
                <img src="@/assets/icons/call.svg" />
                <p>Жду звонка</p>
            </div>
            </button>
            <div class="callback__bot">
                <p>Вы даете согласие на обработку персональных данных</p>
                <p>Обратный звонок</p>
            </div>
            <img src="@/assets/icons/close.svg" class="close" @click="$store.state.openCallBack = false"/>
        </div>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import axios from "axios";
import { mask } from "vue-the-mask";
export default {
    data() {
        return {
          v$: useVuelidate(),
          phone: '',
          name: '',
          cityData: '',
          errors: {
            phoneNumber: false,
            name: false,
            cityData: false,
           },
          city: [
              {
                id: 1,
                name: "Алматы",
                route: 16,
              },
              {
                id: 2,
                name: "Астана",
                route: 14,
              },
            ],
            isLoading: false,
            isButtonDisabled: true,
        };
    },
    validations() {
    return {
      name: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Слишком короткое имя', minLength(3)
          ),
      },
      phone: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      cityData: {
        required: helpers.withMessage('Обязательное поле', required),
      }
    };
  },
    directives: { mask },
    methods: {
      validateFields() {
      // Проверяем поля на ошибки валидации
      this.v$.$touch();
      if (!this.v$.name.$invalid && !this.v$.phone.$invalid && !this.v$.cityData.$invalid) {
        this.isButtonDisabled = false; // Если ошибок нет, разблокируем кнопку
      } else {
        this.isButtonDisabled = true; // Если есть ошибки, блокируем кнопку
      }
    },
    sendData() {
        this.validateFields();
        this.isLoading = true;
        if (!this.isButtonDisabled) {
            const article = {
                Phone: this.phone.replace(/[^0-9]/g, ""),
                Name: this.name,
                Date: Date(),
            };
            const contactData = {
                fields: {
                NAME: this.name,
                PHONE: [
                    {
                    VALUE: this.phone.replace(/[^0-9]/g, ""),
                    VALUE_TYPE: "WORK",
                    },
                ],
                },
            };

            axios
        .post(
          "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.contact.add.json",
          contactData
        )
        .then((response) => {
          if (response.status === 200) {
            // const newContactId = response.data.result;
            // console.log("New Contact ID:", newContactId);
            // this.$store.state.openOk = true;
            const contactData1 = {
              FIELDS: {
                CATEGORY_ID: 2,
                STAGE_ID: "C2:NEW",
                UF_CRM_1604656131: this.cityData?.route,
                UF_CRM_1590070364: 1238,
                SOURCE_ID: "WEBFORM",
                UF_CRM_1613979771: 4064,
                UF_CRM_1634038284: "Hongqi.kz",
                UF_CRM_1586840541: 4140,
                UF_CRM_1611565554: '',
                UF_CRM_1586841138: 4006,
                UF_CRM_1657876982: 1210,
                CONTACT_ID: response.data.result,
                COMMENTS: "Комментарий Комментарий",
              },
            };
            axios
              .post(
                "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.deal.add.json",
                contactData1
              )
              .then((response) => {
          this.isButtonDisabled = true;
                if (response.status === 200) {
                  axios
                .post("https://sheetdb.io/api/v1/098p87j5j1ygf", article)
                    .then((response) => {
                      if (response.status === 201) {
                        this.$store.state.openOk = true;
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                } else {
                  console.log(response);
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                // this.isLoading = false;
              });
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          // this.isLoading = false;
        });
        }
    }
}
};
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
.has-error input {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.close{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px !important;
    height: 25px !important;
}
.callbackbackground{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    }
    .callback{
        padding: 30px;
        background: white;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        &__call{
            display: flex;
            gap: 10px;
            align-items: center;
        }
        img{
            width: 100px;
            height: 100px;
        }
        &__title{
            font-size: 25px;
            font-weight: 600;
        }
        &__sub{
            font-size: 17px;
        }
        select{
          padding: 10px;
            background: rgba(236, 230, 230, 0.952);
            border-radius: 15px;
            outline: none;
            border: none;
        }
        &__number{
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-size: 12px;
            padding: 10px;
            background: rgba(236, 230, 230, 0.952);
            border-radius: 15px;
            input{
                width: 100%;
                border: none;
                outline: none;
                background:  transparent;
            }
        }
        &__time{
            display: flex;
            gap: 10px;
            font-size: 10px;
            .line{
                border: 0.5px solid black;
            }
        }
        button{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px;
            cursor: pointer;
                border: none;
                background: black;
                color: white;
                justify-content: center;
            img{
                width: 30px;
                height: 30px;
            }
        }
        &__bot{
            font-size: 14px;
        }
    }
</style>