<template>
    <img src="@/assets/images/brand/banner.jpg" class="mainimg"/>
    <div class="brandbackground">
        <div class="brand container">
            <div class="brand__title">
                <p>Бренд</p>
            </div>
            <div class="brand__1">
                <div class="brand__1__img">
                    <img src="@/assets/images/brand/1.png"/>
                </div>
                <div class="brand__1__info">
                    <p class="brand__1__info__title">ИСТОРИЯ КОМПАНИИ</p>
                    <p class="brand__1__info__sub">В 1958 году увидел свет первый автомобиль марки Hongqi. С тех пор автомобиль находится на службе у государства и является транспортным средством, которое предпочитают китайские лидеры при участии в крупных национальных мероприятиях. В 1960-х и 1970-х годах автомобиль Hongqi был знаменем автомобильной промышленности Китая. После 1970-х Hongqi стремился стать доступным для массового потребителя и выйти на новый рынок и выхода на новые рынки. В 2018 году бренд Hongqi выпустил новую стратегию и концепцию дизайна, что привело к резкому увеличению продаж на рынке и постепенной реализации идеи экспорта данных автомобилей.</p>
                </div>
            </div>
            <div class="brand__2">
                <div class="brand__2__info">
                    <p class="brand__2__info__title">КОНЦЕПЦИЯ</p>
                    <p class="brand__2__info__sub">Концепция бренда Hongqi подчеркивает «новое благородство», «новую изысканность» и «новые чувства», подразумевая глубокую интеграцию традиционной восточной и мировой культуры, сочетая передовую науку и технологии с эмоциями для предоставления услуг превосходного качества.</p>
                </div>
                <div class="brand__2__img">
                    <img src="@/assets/images/brand/2.png"/>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.mainimg{
    padding-top: 80px;
    @media(max-width:867px){
            display: none;
        }
    @media(max-width:1400px){
            padding-top: 70px;
    }
}
    .brandbackground{
        background-color: #e4e4e5;
        padding: 80px 0px;
    }
    .brand{
        display: flex;
        flex-direction: column;
        gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 1.5625))), 50px);
        &__title{
            font-size: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * -1.7857))), 24px);
            color: #282828;
            font-weight: 700;
        }
        &__1{
            display: flex;
            @media(max-width:867px){
                flex-direction: column;
            }
            &__img{
                width: 50%;
                @media(max-width:867px){
                width: 100%;
            }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__info{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 2.6786))), 60px);
                color: #282828;
                padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                background: white;
                
                @media(max-width:867px){
                width: 100%;
                padding: 20px;
                gap: 30px;
            }
                &__title{
                    font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                    @media(max-width:867px){
                font-size: 22px;
            }
                }
                &__sub{
                    font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.8929))), 24px);
                    @media(max-width:867px){
                font-size: 15px;
            }
                }
            }
        }
        &__2{
            display: flex;
            @media(max-width:867px){
                flex-direction: column-reverse;
            }
            &__img{
                width: 50%;
                @media(max-width:867px){
                width: 100%;
            }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__info{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 2.6786))), 60px);
                color: #282828;
                padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                background: white;
                box-sizing: border-box;
                @media(max-width:867px){
                width: 100%;
                padding: 20px;
                gap: 30px;
            }
                &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                    @media(max-width:867px){
                font-size: 22px;
            }
                }
                &__sub{
                    font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.8929))), 24px);
                    @media(max-width:867px){
                font-size: 14px;
            }
                }
            }
        }
    }
</style>