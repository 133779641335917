<template>
    <img src="@/assets/images/ach/banner.jpg" class="mainimg"/>
    <div class="achbackground">
    <div class="ach container">
        <div class="ach__title">Достижения</div>
        <div class="line1"></div>
        <div class="ach__award">
            <div class="ach__award__title">
                <p>Награды</p>
            </div>
            <div class="ach__award__block1">
                <p>Благодаря своим передовым стандартам качества при производстве и системе всеобъемлющего контроля качества готовых изделий завод Hongqi в Китае два года подряд - в 2018 и 2019 годах - получал награду Best Factory Award (лучший завод).</p>
                <div class="ach__award__block1__images">
                    <img src="@/assets/images/ach/award1.png"/>
                    <img src="@/assets/images/ach/award2.png"/>
                </div>
                <p>В 2018 году: лучший завод Китая в номинации "Качество изготовления продукции"<br/>В 2019 году: лучший завод Китая в номинации "Адаптивность производства"</p>
            </div>
            <div class="ach__award__block2">
                <p>Hongqi H7 дважды удостаивался оценки в пять звезд по уровню безопасности E-NCAP+C-NCAP</p>
                <img src="@/assets/images/ach/badimg.png"/>
            </div>
        </div>
        <div class="line"></div>
        <div class="ach__production">
            <div class="ach__production__title">
                <p>Международное Развитие</p>
            </div>
            <div class="ach__production__block">
                <p>На многих важных международных конференциях:</p>
                <div class="ach__production__block__images">
                    <img src="@/assets/images/ach/prd1.png"/>
                    <img src="@/assets/images/ach/prd2.png"/>
                    <img src="@/assets/images/ach/prd3.png"/>
                    <img src="@/assets/images/ach/prd4.png"/>
                    <img src="@/assets/images/ach/prd5.png"/>
                    <img src="@/assets/images/ach/prd6.png"/>
                </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="ach__salon">
            <div class="ach__salon__title">
                <p>Международный автосалон</p>
            </div>
            <div class="ach__salon__block1">
                <p>Международный автосалон в Дубае</p>
                <div class="ach__salon__block1__images">
                    <img src="@/assets/images/ach/salon1.png"/>
                    <img src="@/assets/images/ach/salon2.png"/>
                    <img src="@/assets/images/ach/salon3.png"/>
                </div>
                <p>12 ноября 2019 года в Центре международной торговли в Дубае открылся 15-й международный автосалон. Данный автосалон является крупнейшим и наиболее престижным для стран Ближнего Востока и Северной Африки. "Красный флаг", как переводится название Hongqi, собирается продемонстрировать посетителям самый мощный продукт под маркой "сделано в Китае".</p>
            </div>
            <div class="ach__salon__block2">
                <p>69-й международный автосалон во Франкфурте</p>
                <div class="ach__salon__block2__images">
                    <img src="@/assets/images/ach/salon4.png"/>
                    <img src="@/assets/images/ach/salon5.png"/>
                </div>
                <p>10 сентября 2019 года Hongqi S9 и Hongqi E115 дебютируют во Франкфурте. Франкфуртский автосалон является крупнейшим в мире автосалоном высшего уровня, в котором принимают участие все знаменитые автомобильные компании мира.</p>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.mainimg{
    padding-top: 80px;
    @media(max-width:867px){
            display: none;
        }
    @media(max-width:1400px){
            padding-top: 70px;
    }
}
.achbackground{
    background-color: #f8f8f8;
}
.line1{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
    width: 10%;
}
.line{
    border: 2px solid rgba(0, 0, 0, 0.534);
}
    .ach{
        display: flex;
        flex-direction: column;
        gap: 70px;
        padding-top: 70px;
        padding-bottom: 70px;
        color: #282828;
        @media(max-width:867px){
        gap: 20px;
        padding-top: 80px;
    }
        &__title{
            font-size: 40px;
            font-weight: 700;
            @media(max-width:867px){
                font-size: 24px;
            }
        }
        &__award{
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media(max-width:867px){
                gap: 20px;
            }
            &__title{
                font-size: 30px;
                font-weight: 600;
            }
            &__block1{
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: white;
                @media(max-width:867px){
                padding: 10px;
            }
                &__images{
                    display: flex;
                    justify-content: space-between;
                    @media(max-width:867px){
                flex-direction: column;
                gap: 20px;
            }
                    img{
                        width: 47%;
                        @media(max-width:867px){
                width: 95%;
            }
                    }
                }
            }
            &__block2{
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: white;
                @media(max-width:867px){
                padding: 10px;
            }
            }
        }
        &__production{
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media(max-width:867px){
                gap: 20px;
            }
            &__title{
                font-size: 30px;
                font-weight: 600;
                @media(max-width:867px){
                font-size: 20px;
            }
            }
            &__block{
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: white;
                font-weight: 600;
                @media(max-width:867px){
                padding: 10px;
            }
                &__images{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px;
                    @media(max-width:867px){
                        flex-direction: column;
                        gap: 20px;
                    }
                    img{
                        width: 30%;
                        @media(max-width:867px){
                        width: 100%;
                        }
                    }
                }
            }
        }
        &__salon{
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media(max-width:867px){
                gap: 20px;
            }
            &__title{
                font-size: 30px;
                font-weight: 600;
                @media(max-width:867px){
                font-size: 20px;
            }
            }
            &__block1{
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: white;
                font-weight: 700;
                @media(max-width:867px){
                padding: 10px;
            }
                &__images{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px;
                    @media(max-width:867px){
                flex-direction: column;
                gap: 20px;
                    img{
                        width: 31%;
                        @media(max-width:867px){
                            width: 100%;
                        }
                    }
                }
            }
        }
            &__block2{
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: white;
                font-weight: 700;
                @media(max-width:867px){
                padding: 10px;
                gap: 20px;
            }
                &__images{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px;
                    @media(max-width:867px){
                flex-direction: column;
                gap: 20px;
            }
                    img{
                        width: 47%;
                        @media(max-width:867px){
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
}
</style>