<template>
    <img src="@/assets/images/social/banner.jpg"  class="mainimg"/>
    <div class="socialbackground">
    <div class="social container">
        <div class="social__title">
            <p>Социальная<br/>ответственность</p>
        </div>
        <div class="line"></div>
        <div class="social__block1">
            <p>Мы уделяем внимание скоординированному и устойчивому развитию бизнеса и экономики, общества и окружающей среды, реагируем на Цели устойчивого развития Организации Объединенных Наций и стремимся достичь беспроигрышной ситуации с точки зрения экономических, социальных и экологических выгод.</p>
            <img src="@/assets/images/social/block1img.png"/>
            <p class="social__block1__title">Экологическая устойчивость</p>
            <p>В ответ на цели Организации Объединенных Наций в сфере устойчивого развития Hongqi реализует новую модель развития "низкоуглеродная энергетика, сохранение, переработка, баланс и устойчивость". Компания активно продвигает концепцию "зеленого" развития и технологические инновации, развивает отрасль новых источников энергии, сокращает выбросы парниковых газов и постоянно улучшает качество окружающей среды, создавая благоприятную среду обитания для наземных организмов.</p>
            <img src="@/assets/images/social/block1img2.png"/>
        </div>
        <div class="line"></div>
        <div class="social__block2">
            <div class="social__block2__title">
                <p>Развитие бизнеса в сфере новых источников энергии</p>
            </div>
            <p>Мы продолжаем совершенствовать стратегию развития бизнеса в сфере новых источников энергии, продолжаем продвигать исследования и разработки новых энергетических технологий и инновационных продуктов, укреплять координацию и сотрудничество во всей цепочке новой энергетической отрасли и совместно способствовать здоровому и быстрому развитию новой энергетической автомобильной промышленности в мире.</p>
            <div class="social__block2__cards">
                <div class="social__block2__cards__card" v-for="item in block2cards" :key="item">
                    <p class="social__block2__cards__card__year">{{ item.year }}</p>
                    <p class="social__block2__cards__card__text">{{ item.text }}</p>
                </div>
            </div>
            <div class="social__block2__title2">
                <p>Stakeholder participation</p>
            </div>
            <p>Устойчивое развитие китайского бренда HONGQI неотделимо от общения, участия и сотрудничества заинтересованных сторон. Мы придаем большое значение ожиданиям и требованиям заинтересованных сторон, создаем и совершенствуем каналы раскрытия информации и своевременный, прозрачный и эффективный механизм коммуникации с заинтересованными сторонами.</p>
        </div>
        <div class="line"></div>
        <div class="social__block3">
            <div class="social__block3__title">
                <p>Развитие заинтересованных сторон</p>
            </div>
            <p>Наша концепция устойчивого развития неотделима от совместных усилий заинтересованных сторон. Мы ценим стремления и требования заинтересованных сторон и рассчитываем на сотрудничество с ними для содействия устойчивому социальному развитию.</p>
            <div class="social__block3__cards">
                <div class="social__block3__cards__card" v-for="item in block3cards" :key="item">
                    <div class="social__block3__cards__card__top">
                        <p>Заинтересованные стороны</p>
                        <p>Ожидания и требования</p>
                    </div>
                    <div class="social__block3__cards__card__bot">
                        <img :src="require('@/assets/images/social/' + item.img + '.png')"/>
                    <div class="social__block3__cards__card__bot__posibilities">
                        <div class="social__block3__cards__card__bot__posibilities__posibility" v-for="posibility in item.possibilities" :key="posibility">
                            <p>{{ posibility.name }}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            block2cards: [
                {
                    year: "2019",
                    text: "Прототип первого двухмоторного гибридного автомобиля Hongqi H5 сошел с конвейера. Независимая гибридная трансмиссия на основе конфигурации двухмоторного привода Hongqi DHT300 успешно прошла испытания. Успешно запущен первый аккумуляторный блок Hongqi для автомобилей на топливных элементах. Hongqi E-HS3 успешно выиграл чемпионат в категории компактных полностью электрических внедорожников в китайском конкурсе автомобилей на новых источниках энергии. Наша концепция устойчивого развития неотделима от совместных усилий заинтересованных сторон. Мы ценим стремления и требования заинтересованных сторон и рассчитываем на сотрудничество с ними для содействия устойчивому социальному развитию. Первый полностью электрический автомобиль Hongqi EV получил высшую награду в раллийных соревнованиях в категории транспортных средств на новых источниках энергии."
                },
                {
                    year: "2020",
                    text: "25% автомобилей бренда Hongqi будут служить целям достижения чистоты транспортных средств на новых источниках энергии, а также гарантий полной безопасности и выносливости длиною в 600 километров без каких-либо отказов. Выпущен роскошный полноразмерный электрический внедорожник Hongqi E-HS9. Этот автомобиль представляет собой коллекционную модель, разработанную на базе новой технологической платформы 'умная система электрификации'. Это флагман линейки автомобилей Hongqi на новых источниках энергии."
                },
                {
                    year: "2030",
                    text: "25% автомобилей бренда Hongqi будут служить целям достижения чистоты транспортных средств на новых источниках энергии, а также гарантий полной безопасности и выносливости длиною в 600 километров без каких-либо отказов."
                },
                {
                    year: "2025",
                    text: "Наша цель – достичь высокой чистоты транспортных средств на новых источниках энергии и реализовать серийное производство твердотельных аккумуляторов. Продажи автомобилей на новых источниках энергии с запасом хода 1000 километров будут составлять 40% продаж бренда Hongqi."
                }

            ],
            block3cards: [
                {
                    img: "1",
                    possibilities:[
                        {
                            name: "Соблюдение дисциплины и закона"
                        },
                        {
                            name: "Целостность"
                        },
                        {
                            name: "Управление рисками"
                        },
                        {
                            name: "Качество и безопасность изделий"
                        },
                    ]
                },
                {
                    img: "2",
                    possibilities:[
                        {
                            name: "Сохранение и оценка активов"
                        },
                        {
                            name: "Результаты деятельности"
                        },
                        {
                            name: "Конкурентоспособность на рынке"
                        },
                        {
                            name: "Крупные инвестиции в проект и доход"
                        },
                    ]
                },
                {
                    img: "3",
                    possibilities:[
                        {
                            name: "Высокое качество изделий"
                        },
                        {
                            name: "Внимательное обслуживание"
                        },
                        {
                            name: "Инновации в сфере продуктов и услуг"
                        },
                        {
                            name: "Безопасность в путешествии"
                        },
                    ]
                },
                {
                    img: "4",
                    possibilities:[
                        {
                            name: "Целостность"
                        },
                        {
                            name: "Взаимовыгодное сотрудничество"
                        },
                        {
                            name: "Ответственные закупки"
                        },
                        {
                            name: "Зеленая цепочка поставок"
                        },
                    ]
                },
                {
                    img: "5",
                    possibilities:[
                        {
                            name: "Защита прав и интересов сотрудников"
                        },
                        {
                            name: "Обучение и развитие персонала"
                        },
                        {
                            name: "Охрана труда и техника безопасности"
                        },
                        {
                            name: "Баланс между работой и личной жизнью"
                        },
                    ]
                },
                {
                    img: "6",
                    possibilities:[
                        {
                            name: "Экологически чистая продукция"
                        },
                        {
                            name: "Энергосбережение"
                        },
                        {
                            name: "Зеленая культура"
                        },
                        {
                            name: "Снижение негативного воздействия на окружающую среду"
                        },
                        {
                            name: "Действия, направленные на предотвращение изменения климата"
                        }
                    ]
                },
                {
                    img: "1",
                    possibilities:[
                        {
                            name: "Управление локализацией"
                        },
                        {
                            name: "Благотворительность"
                        },
                        {
                            name: "Забота об особых людях"
                        },
                    ]
                },

            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.mainimg{
    padding-top: 80px;
    @media(max-width:867px){
            display: none;
        }
    @media(max-width:1400px){
            padding-top: 70px;
    }
}
.socialbackground{
    background-color: #f8f8f8;
}
.line{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
}
    .social{
        display: flex;
        flex-direction: column;
        gap: 70px;
        padding-top: 70px;
        padding-bottom: 70px;
        color: #282828;
        padding-left: 10px;
        padding-right: 10px;
        @media(max-width:867px){
            padding-top: 75px;
            gap: 20px;
        }
        &__title{
            font-size: 40px;
            font-weight: 700;
            @media(max-width:867px){
            font-size: 24px;
        }
        }
        &__block1{
            display: flex;
            flex-direction: column;
            gap: 50px;
            font-size: 24px;
            @media(max-width:867px){
            font-size: 14px;
            gap: 20px;
        }
            &__title{
                font-size: 28px;
                font-weight: 600;
            }
        }
        &__block2{
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media(max-width:867px){
            gap: 20px;
        }
            &__title{
                font-size: 38px;
                font-weight: 600;
                @media(max-width:867px){
                    font-size: 20px;
                }
            }
            &__cards{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                @media(max-width:867px){
                    gap: 20px;
                }
                &__card{
                    width: 43%;
                    background: white;
                    padding: 40px;
                    height: fit-content;
                    border: 2px solid #d9d9d9;
                    border-radius: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    @media(max-width:867px){
                        width: 100%;
                        padding: 10px;
                        gap: 10px;
                    }
                    &__year{
                        font-size: 48px;
                        color: #333333;
                        @media(max-width:867px){
                            font-size: 30px;
                        }
                    }
                    &__text{
                        font-size: 20px;
                        color: #333333;
                        line-height: 2;
                        @media(max-width:867px){
                            font-size: 12px;
                        }
                    }
                }
            }
            &__title2{
                font-size: 28px;
                font-weight: 600;
            }
        }
        &__block3{
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media(max-width:867px){
                gap: 20px;
            }
            &__title{
                font-size: 40px;
                font-weight: 700;
                @media(max-width:867px){
                    font-size: 24px;
                }
            }
            &__cards{
                 display: flex;
                 flex-wrap: wrap;
                 gap: 50px;
                 @media(max-width:867px){
                        gap: 20px;
                    }
                 &__card{
                    width: 48%;
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #8fb641;
                    border-radius: 30px;
                    @media(max-width:1400px){
                        width: 100%;
                    }
                    @media(max-width:867px){
                        width: 100%;
                    }
                    
                    &__top{
                        display: flex;
                        justify-content: space-between;
                        padding: 40px;
                        background-color: #8fb641;
                        border-radius: 30px 30px 0px 0px;
                        color: white;
                        @media(max-width:867px){
                            padding: 10px;
                            font-size: 14px;
                        }
                    }
                    &__bot{
                        display: flex;
                        padding: 40px;
                        justify-content: space-between;
                        align-items: center;
                        @media(max-width:867px){
                            padding: 10px;
                        }
                        img{
                            width:35%;
                        }
                        &__posibilities{
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            width: 50%;
                            @media(max-width:867px){
                                width: 70%;
                            }
                            &__posibility{
                                padding: 15px;
                                border: 2px solid #8fb641;
                                border-radius: 30px;
                                color: #8fb641;
                                font-size: 20px;
                                @media(max-width:867px){
                                    font-size: 12px;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                 }
            }
        }
    }
</style>