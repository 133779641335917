<template>
  <CallBackModal v-if="$store.state.openCallBack == true"/>
  <GoodJobView v-if="$store.state.openOk == true"/>
  <BurgerView v-if="$store.state.openBurger == true"/>
  <div class="desctop">
<div class="headerBackground">
    <div class="header container">
        <div class="header__left" >
          <router-link to="/"><img src="@/assets/icons/logo.svg"/></router-link>
        </div>
        <div class="header__right" >
                <div class="header__right__models">
                  <router-link to="/model" style="text-decoration: none; color: #808080;"><p class="titleitems">Модели</p></router-link>
                  <div class="headermodels">
                  <div class="headermodels__top" v-if="header.length">
                    <p @click="showModel(1)" :class="{active: currentModel == 1}">{{ header[0].title }}</p>
                    <p @click="showModel(2)" :class="{active: currentModel == 2}">{{ header[1].title }}</p>
                    <p @click="showModel(3)" :class="{active: currentModel == 3}">{{ header[2].title }}</p>
                  </div>
                  <div class="headermodels__section" v-if="currentModel == 1 && header.length">
                    <div class="headermodels__section__car" @click="$router.push('/models/' + header[0].models[0].slug)">
                      <p>{{header[0].models[0].title}}</p>
                      <img :src="header[0].models[0].logo" />
                    </div>
                    <div class="headermodels__section__car" @click="$router.push('/models/' + header[0].models[1].slug)">
                      <p>{{header[0].models[1].title}}</p>
                      <img :src="header[0].models[1].logo" />
                    </div>
                  </div>
                  <div class="headermodels__section" v-if="currentModel == 2">
                    <div class="headermodels__section__car" @click="$router.push('/models/' + header[1].models[0].slug)">
                      <p>{{ header[1].models[0].title }}</p>
                      <img :src="header[1].models[0].logo" />
                    </div>
                  </div>
                  <div class="headermodels__section" v-if="currentModel == 3">
                    <div class="headermodels__section__car" @click="$router.push('/models/' + header[2].models[0].slug)">
                      <p>{{header[2].models[0].title}}</p>
                      <img :src="header[2].models[0].logo" />
                    </div>
                  </div>
                </div>
                </div>
                  <div class="header__right__dillers">
                    <router-link to="/dilers" style="text-decoration: none; color: #808080;"><p class="titleitems">Дилеры</p></router-link>
                </div>
                <div class="header__right__news">
                <router-link to="/news" style="text-decoration: none; color: #808080;"><p class="titleitems">Новости</p></router-link>
              </div>
                  <div class="header__right__world">
                    <router-link to="/world" style="text-decoration: none; color: #808080;"><p class="titleitems">Мир Hongqi</p></router-link>
                    <div class="headerworld">
                      <div class="headerworld__left">
                        <router-link to="/history" style="text-decoration: none; color: inherit"><p class="headerworld__left__history">История</p></router-link>
                        <router-link to="/brand" style="text-decoration: none; color: inherit"><p class="headerworld__left__brand">Бренд</p></router-link>
                        <router-link to="/develop" style="text-decoration: none; color: inherit"><p class="headerworld__left__development">Исследования и Разработки</p></router-link>
                        <router-link to="/achievement" style="text-decoration: none; color: inherit"><p class="headerworld__left__achievment">Достижения</p></router-link>
                        <router-link to="/social" style="text-decoration: none; color: inherit"><p class="headerworld__left__social">Социальная ОТВЕТСТВЕННОСТЬ</p></router-link>
                      </div>
                      <div class="headerworld__right">
                        <img src="@/assets/images/worldhistoryback.png" class="headerworld__right__history"/>
                      </div>

                    </div>
                  </div>
                  <div class="header__right__library">
                    <router-link to="/library" style="text-decoration: none; color: #808080;"><p class="titleitems">Библиотека</p></router-link>
                  <div class="headerlibrary"> 
                    <div class="headerlibrary__left">
                      <p>HONGQI ФОТОГРАФИИ</p>
                      <p>HONGQI ВИДЕО</p>
                      <p>HONGQI ДАННЫЕ</p>
                    </div>
                    <div class="headerlibrary__right">
                      <img src="@/assets/images/headerlibrary.jpg"/>
                    </div>
                  </div>
                  </div>
                <div class="header__right__lang">
                  <img src="@/assets/icons/lang.svg"/>
                  <Select :data="data1" placeholderCustom="Ru" v-model="value1" @vModelCustom="selectThisValueParent"/>
              </div>
              <div class="header__right__call">
                <img src="@/assets/icons/call5588.svg"/>
                <p>5588</p>
              </div>
              </div>
        </div>
    </div>
</div>
<img src="@/assets/icons/callback.svg" class="callback" @click="$store.state.openCallBack = !$store.state.openCallBack"/>
<div class="mobile">
  <div class="headermobile">
    <div class="headermobile__left">
      <router-link to="/"><img src="@/assets/images/mobilelogo.png"/></router-link>
    </div>
    <div class="headermobile__right">
      <div class="headermobile__right__language">
      <img src="@/assets/icons/lang.svg"/>
      <select @change="selectOption" class="mobileselect">
        <option value="option1">Ru</option>
        <option value="option2">Kz</option>
      </select>
    </div>
    <div class="headermobile__right__burger">
      <img src="@/assets/icons/menu.svg" @click="$store.state.openBurger = true"/>
    </div>
    </div>
  </div>
</div>
</template>
<script>
import Select from '@/components/UI/Select.vue'
import CallBackModal from '../CallBackModal.vue';
import GoodJobView from '@/components/GoodJobView.vue';
import BurgerView from '@/components/BurgerView.vue';
export default {
    components: {Select, CallBackModal, GoodJobView, BurgerView},
    data() {
    return {
      value1: 1,
      header: [],
      data1: [
        {
          value: 1,
          label: "RU"
        },
        {
          value: 2,
          label: "KZ"
        }
      ],
      shouldHide: false,
      currentModel: 1,
    };
  },
  async created() {
    await this.$axios.get(`/header`)
    .then(res => {
      this.header = res.data[0]
    })
    .catch(err => {
    })
},
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Определите условие, при котором должна скрываться часть заголовка
      // В данном примере, мы скрываем заголовок, когда пользователь прокручивает вниз на 100px
      this.shouldHide = window.scrollY > 0;
    },
    showModel(value) {
      this.currentModel = value;
    },

    selectThisValueParent(value) {
      this.value1 = value;
    }
  },
  watch: {
    $route() {
      this.$store.state.openBurger = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.callback{
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 999;
  &:hover{
    transition: all 0.5s ease;
    transform: scale(1.2);
  }
}
.active{
  font-weight: 700;
  color: black;
}
.custom-select{
  @media(max-width:1400px){
    font-size: 15px;
  }
}
.headerlibrary{
  position: fixed;
  width: 100%;
  margin-top: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px);
  background: #F3F3F3;
  box-sizing: border-box;
  left: 0%;
  padding: 50px 250px;
  display: flex;
  transform: translateY(0px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden;
  align-items: center;
  justify-content: space-between;
  @media(max-width:1710px){
              padding: 50px 100px;
            }
  &:hover{
    opacity: 1;
    transform: translateY(0);
    transition: 1.4s ease ;
    transition-delay: 0s;
    visibility: visible;
  }
  &__left{
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    color: grey;
    font-size: 18px;
  }
  &__right{
    width: 65%;
  }
  img{
    height: 400px;
    width: 100%;
  }
}
.titleitems{
  &:hover{
    p{
    color: black;
    }
  }
}
.headerdillers{
  transform: translateY(0px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden;
  position: fixed;
  background: #F3F3F3;
  box-sizing: border-box;
  margin-top: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px);
  width: 100%;
  left: 0%;
  padding: 50px 0px 50px 150px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  font-size: 21px;
  text-transform: none;
  color: #6d6e6f;
  &:hover{
    opacity: 1;
    transform: translateY(0);
    transition: 0.7s ease ;
    transition-delay: 0s;
    visibility: visible;
  }
}
.headerworld{
  position: fixed;
  width: 100%;
  margin-top: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px);
  background: #F3F3F3;
  box-sizing: border-box;
  left: 0%;
  padding: 50px 250px;
  display: flex;
  transform: translateY(0px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden;
  align-items: center;
  justify-content: space-between;
  @media(max-width:1710px){
              padding: 50px 100px;
            }
  &:hover{
    opacity: 1;
    transform: translateY(0);
    transition: 1.4s ease ;
    transition-delay: 0s;
    visibility: visible;
  }
  &__left{
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 18px;
    color: grey;
    &__history{
    }
    &__development{

    }
    &__achievment{

    }
    &__social{

    }
    &__brand{

    }
  }
  &__right{
    width: 65%;
    &__history{
    }
    &__development{
      display: none;
    }
    &__achievment{
      display: none;
    }
    &__social{
      display: none;
    }
    &__brand{
      display: none;
    }
    img{
      width: 100%;
      height: 400px;
    }
  }
}
.headermobile{
    background-color: black;
    box-sizing: border-box;
    padding: 0px 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 75px;
    &__left{
      img{
        width: 55px;
        height: 55px;
      }
    }
    &__right{
      display: flex;
      gap: 27px;
      align-items: center;
      img{
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6868%) hue-rotate(55deg) brightness(125%) contrast(95%);
      }
      &__language{
        display: flex;
        gap: 5px;
        align-items: center;
        .mobileselect{
          border: none;
          background: inherit;
          color: white;
        }
      }
    }
}
.headermodels{  
  transform: translateY(0px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden;
  position: fixed;
  height: 350px;
  background: #F3F3F3;
  width: 100%;
  padding: 50px 150px;
  margin-top: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px);
  left: 0%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  &:hover{
    opacity: 1;
    transform: translateY(0);
    transition: 0.4s ease ;
    transition-delay: 0s;
    visibility: visible;
  }
  &__top{
    display: flex;
    gap: 50px;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    color: #666666;
    P{
      cursor: pointer;
    }
  }
  &__section{
    display: flex;
    gap: 100px;
    font-size: 70px;
    &__car{
      display: flex;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      color: #aaa7a7;
      img{
        width: 240px;
        height: 120px;
      }
    }
  }
}
.headerBackground{
    background: #F3F3F3;
    position: fixed;
    z-index: 999;
    width: 100%;
    box-sizing: border-box;
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
        &__right{
            display: flex;
            width: 70%;
            color:#666666;
            align-items: center;
            justify-content: space-between;
            font-weight: 700;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.6696))), 18px);;
            text-transform: uppercase;
            &__dillers{
              padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
              &:hover{
                .headerdillers{
                  opacity: 1;
                  transform: translateY(0px);
                  transition: 0.8s ease ;
                  transition-delay: 0.1s;
                  visibility: visible;
                }
              }
            }
            &__library{
              padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
              &:hover{
                .headerlibrary{
                  opacity: 1;
                  transform: translateY(0);
                  transition: 0.8s ease ;
                  transition-delay: 0.1s;
                  visibility: visible;
                }
              }
            }
            &__news{
              padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
            }
            &__world{
              padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
              &:hover{
                .headerworld{
                  opacity: 1;
                  transform: translateY(0);
                  transition: 0.8s ease ;
                  transition-delay: 0.1s;
                  visibility: visible;
                    }
                  }
            }
            &__models{
              padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;

              &:hover{
                .headermodels{
                  opacity: 1;
                  transform: translateY(0);
                  transition: 0.8s ease ;
                  transition-delay: 0.1s;
                  visibility: visible;
                }
              }
            }
            &__lang{
              display: flex;
              align-items: center;
              gap: 5px;
              Select{
                font-size: 18px;
                background: inherit;
                outline: none;
              }
            }
            &__call{
              display: flex;
              gap: 5px;
              align-items: center;

            }
        }
        &__left{
          img{
          @media(max-width:1400px){
              height: 70px;
              width: 70px;
            }
          }
        }
    }
}
</style>