<template>
    <div class="okbackground" @click="$store.state.openOk = false">
        <div class="ok">
            <p class="ok__text">Спасибо. Ваша заявка принята!</p>
            <img src="@/assets/icons/close.svg" class="close" @click="$store.state.openOk = false"/>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.close{
    position: absolute;
    right: 20px;
    top: 20px;
}
    .okbackground{
        position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    @media(max-width:867px){
            padding: 20px;
        }
    }
    .ok{
        padding: 120px 0px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        position: relative;
        font-size: 36px;
        color:#6d6e6f;
        background: white;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 700;
        &__text{
            width: 60%;
        }
        button{
            width: 50%;
            background: red;
            padding: 10px;
            font-size: 20px;
            color: white;
            border: none;
            border-radius: 30px;
        }
    }
</style>