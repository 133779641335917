<template>
    <div class="testbackground">
        <div class="test">
            <div class="test__title">
                ТЕСТ-ДРАЙВ
            </div>
            <div class="test__inputs">
                <div class="test__inputs__input">
                    <input v-model="testData.name" placeholder="Имя: *" type="text" :class="{ 'invalid': validationErrors.name }"/>
                    <p class="error-message">{{ validationErrors.name }}</p>
                </div>
                <div class="test__inputs__select">
                    <select v-model="testData.dealer" id="dealerSelect" :class="{ 'invalid': validationErrors.dealer }">
                        <option value="" disabled selected>Дилер: </option>
                        <option v-for="(item, idx) in dealer" :key="'d' + idx" :value="item">{{ item.name }}</option>
                    </select>
                    <p class="error-message">{{ validationErrors.dealer }}</p>
                </div>
                <div class="test__inputs__input">
                    <input v-model="testData.phone" v-mask="'+7 (###) ###-##-##'" placeholder="Моб.Телефон: +7" type="text" :class="{ 'invalid': validationErrors.phone }"/>
                    <p class="error-message">{{ validationErrors.phone }}</p>
                </div>
                <div class="test__inputs__select">
                    <select v-model="testData.model" id="citySelect" :class="{ 'invalid': validationErrors.model }">
                        <option value="" disabled selected>Модель: </option>
                        <option v-for="(item,idx) in models" :key="'m'+ idx" :value="item">{{item.name}}</option>
                    </select>
                    <p class="error-message">{{ validationErrors.model }}</p>
                </div>
            </div>
                        <div class="test__bot">
                <input v-model="testData.agreement" type="checkbox" style="width: 30px; height: 30px;" :class="{ 'invalid': validationErrors.Agreement}"/>
                <p class="error-message">{{ validationErrors.agreement }}</p>
                <div class="text">
                    <p class="text__title">Я СОГЛАСЕН С ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ</p>
                    <p class="text__sub">Что касается личной информации, которую вы предоставляете, мы будем уважать вашу
                        конфиденциальность и сохраним данную информацию в тайне.</p>
                </div>
            </div>
            <button @click="sendData" :disabled="isLoading">
                <div v-if="isLoading">
                    <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            <div v-else class="callback__call">
                <p>ОТПРАВИТЬ</p>
            </div>
            </button>
        </div>
    </div>
</template>
<script>

import { ref, reactive, computed } from 'vue';
import { mask } from "vue-the-mask";
import axios from "axios";
export default {
    directives: { mask },
    data(){
        return{
        validationErrors: {
        name: '',
        dealer: '',
        phone: '',
        model: '',
        agreement: '',
    },
        isLoading: false,
        testData: {
            name: null,
            dealer: '',
            model: '',
            phone: '',
            agreement: false
        },
        models: [
            {
                id: 1,
                name: 'E-HS9',
                model: 'ehs9',
                route: 13966
            },
            {
                id: 2,
                name: 'HS5',
                model: "hs5",
                route: 13964
            },
            {
                id: 3,
                name: 'H5',
                model: "h5",
                route: 13960
                
            },
            {
                id: 4,
                name: 'H9',
                model: "h9",
                route: 13962
            }
        ],
        dealer: [
            {
                id: 1,
                name: 'Алматы Allur',
                route: 16,
            },
            {
                id: 2,
                name: 'Астана Allur',
                route: 14
            },
        ],
        }
    },
    methods: {
        sendData() {
         console.log('EFEF')   
        for (const key in this.validationErrors) {
            this.validationErrors[key] = '';
        }
        let isValid = true;
        
        if (!this.testData.name) {
            this.validationErrors.name = 'Пожалуйста, введите имя';
            isValid = false;
        }
        if (!this.testData.dealer) {
            this.validationErrors.dealer = 'Пожалуйста, выберите дилера';
            isValid = false;
        }
        if (!this.testData.phone) {
            this.validationErrors.phone = 'Пожалуйста, введите номер телефона';
            isValid = false;
        }
        if (!this.testData.model) {
            this.validationErrors.model = 'Пожалуйста, выберите модель';
            isValid = false;
        }
        if (!this.testData.agreement) {
            this.validationErrors.agreement = 'Пожалуйста, подтвердите согласие';
            isValid = false;
        }
        if (!isValid) {
            return; // Stop processing if validation failed
        }
         this.isLoading = true;    
        const article = {
        Name: this.testData?.name,
        Dealer: this.testData?.dealer?.name,
        Phone: this.testData?.phone.replace(/[^0-9]/g, ""),
        Agreement: this.testData?.agreement,
        Model: this.testData?.model?.model,
        Date: Date()
      };
      const contactData = {
        fields: {
          NAME: this.testData?.name,
          PHONE: [
            {
              VALUE: this.testData?.phone.replace(/[^0-9]/g, ""),
              VALUE_TYPE: "WORK",
            },
          ],
        },
      };

      axios
        .post(
          "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.contact.add.json",
          contactData
        )
        .then((response) => {
          if (response.status === 200) {
            // const newContactId = response.data.result;
            // console.log("New Contact ID:", newContactId);
            // this.$store.state.openOk = true;
            const contactData1 = {
              FIELDS: {
                CATEGORY_ID: 2,
                STAGE_ID: "C2:NEW",
                UF_CRM_1604656131: this.testData?.dealer?.route,
                UF_CRM_1590070364: 1238,
                SOURCE_ID: "WEBFORM",
                UF_CRM_1613979771: 4064,
                UF_CRM_1634038284: "Hongqi.kz",
                UF_CRM_1586840541: 4140,
                UF_CRM_1611565554: this.testData?.model?.route,
                UF_CRM_1586841138: 4006,
                UF_CRM_1657876982: 1210,
                CONTACT_ID: response.data.result,
                COMMENTS: "Комментарий Комментарий",
              },
            };
            axios
              .post(
                "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.deal.add.json",
                contactData1
              )
              .then((response) => {
                if (response.status === 200) {
                  axios
                    .post(
                      "https://sheetdb.io/api/v1/098p87j5j1ygf?sheet=Test",
                      article
                    )
                    .then((response) => {
                      if (response.status === 201) {
                        this.$store.state.openOk = true;
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                } else {
                  console.log(response);
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                this.testData.name = '';
                        this.testData.dealer = '';
                        this.testData.model = '';
                        this.testData.phone = '';
                        this.testData.agreement = false;
              });
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
        });
        }
    },
}
</script>
<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
 .invalid {
        border-color: red; /* or any other visual indication */
    }
    .error-message {
        color: red;
        font-size: 12px;
        margin-top: 4px;
    }
.text {
    display: flex;
    flex-direction: column;

    &__title {
        text-transform: uppercase;
        color: #6d6e6f;
        font-size: 25px;

        @media(max-width:867px) {
            font-size: 10px;
        }
    }

    &__sub {
        font-size: 16px;

        @media(max-width:867px) {
            font-size: 8px;
        }
    }
}

.testbackground {
    background-image: url('@/assets/images/testback.jpg');
    background-size: 100% 100%;
}

.test {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    padding: 50px 250px;
    gap: 50px;

    @media(max-width:867px) {
        padding: 50px;
    }

    &__title {
        text-align: center;
        font-size: 40px;
        color: black;
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 120px;
        row-gap: 50px;

        @media(max-width:867px) {
            row-gap: 20px;
        }
        &__input{
            width: 43%;
        input {
            width: 100%;
            padding: 25px;
            outline: none;
            background: inherit;
            border: 1.5px solid black;

            @media(max-width:867px) {
                width: 100%;
            }

            &::placeholder {
                color: black;
                font-size: 15px;
            }
        }
    }
    &__select{
        width: 43.7%;
        select {
            width: 100%;
            padding: 25px;
            outline: none;
            background: inherit;
            border: 1.5px solid black;
            color: black;

            @media(max-width:867px) {
                width: 100%;
            }

            &::placeholder {
                color: black;
                font-size: 15px;
            }
        }
    }
    }

    &__bot {
        display: flex;
        gap: 20px;
        align-items: center;

        @media(max-width:867px) {
            align-items: start;
        }
    }

    button {
        width: 25%;
        padding: 15px;
        background-color: #42373c;
        border-radius: 30px;
        border: none;
        color: white;
        margin-right: auto;
        cursor: pointer;
        margin-left: auto;

        @media(max-width:867px) {
            width: 50%;
        }

        &:hover {
            transition: all 0.5s ease;
            background: red;
        }
    }
}
</style>