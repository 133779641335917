<template>
    <div class="hat">
        <div class="hatline"></div>
        <div class="hat__title">
            <router-link to="/models/h9" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>H9</p>
            </router-link>
            <router-link to="/models/e-hs9" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>E-HS9</p>
            </router-link>
            <router-link to="/models/h5" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>H5</p>
            </router-link>
            <router-link to="/models/hs5" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>HS5</p>
            </router-link>
        </div>
        <div class="hatline"></div>
    </div>
    <div class="video-background">
        <video autoplay muted playsinline v-if="model?.model?.video">
            <source :src="model.model.video" type="video/mp4" />
        </video>
        <router-link to="/h5#test"><button class="custom-button">ТЕСТ-ДРАЙВ</button></router-link>
        <p class="custom-text" v-if="model?.model?.title">HONGQI<br>
        {{ model.model.title }}</p>
    </div>
    <div class="cars" v-if="model">
        <div class="selected-car">
            <img v-if="model?.colors" :src="model.colors[this.activeCarIndex].image" alt="" />
        </div>
        <div class="selected-img">
            <div v-for="(car, index) in model.colors" :key="index" @click="selectCar(index)"
                :class="{ active: index === activeCarIndex }" class="imgcolor">
                <img :src="car.hex" />
            </div>
        </div>
        <div class="param container">
            <div class="param__title">
                <p>{{ model.complectations[0].title }}</p>
            </div>
            <div class="param__main">
                <div class="param__main__left">
                    <div class="param__main__left__1">
                        <p class="character">{{ model.mainSpecification.specifications[0].title }}</p>
                        <p class="character__info">{{ model.mainSpecification.specifications[0].value_for_1 }}</p>
                    </div>
                    <div class="param__main__left__2">
                        <p class="character">{{ model.mainSpecification.specifications[1].title }}</p>
                        <p class="character__info">{{ model.mainSpecification.specifications[1].value_for_1 }}</p>
                    </div>
                </div>
                <div class="line"></div>
                <div class="param__main__left">
                    <div class="param__main__left__1">
                        <p class="character">{{ model.mainSpecification.specifications[2].title }}</p>
                        <p class="character__info">{{ model.mainSpecification.specifications[2].value_for_1 }}</p>
                    </div>
                    <div class="param__main__left__2">
                        <p class="character">{{ model.mainSpecification.specifications[3].title }}</p>
                        <p class="character__info">{{ model.mainSpecification.specifications[3].value_for_1 }}</p>
                    </div>
                </div>
                <div class="line"></div>
                <div class="param__main__left">
                    <div class="param__main__left__1">
                        <p class="character">{{ model.mainSpecification.specifications[4].title }}</p>
                        <p class="character__info">{{ model.mainSpecification.specifications[4].value_for_1 }}</p>
                    </div>
                    <div class="param__main__left__2">
                        <p class="character">{{ model.mainSpecification.specifications[5].title }}</p>
                        <p class="character__info">{{ model.mainSpecification.specifications[5].value_for_1 }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="parameters container" v-if="model">
        <div class="parameters__title">
            <p>Параметры модели</p>
        </div>
        <div class="parameters__block">
            <div class="parameters__block__top">
                <div class="parameters__block__top__left" v-for="item in model.complectations" :key="item.id">
                    <p>{{ item.title }}</p>
                    <p style="font-size: min(max(14px, calc(0.875rem + ((1vw - 10.24px) * 1.2277))), 25px);">{{ formatPrice(item.price)}} тг</p>
                </div>
            </div>
            <div class="parameters__block__bot">
                <div class="parameters__block__bot__in" v-for="item in model.specificationCategories" :key="item">
                    <div @click="toggleParameter(item)" class="parameters__block__bot__in__top">
                        <div class="parameters__block__bot__in__top__name">
                            {{ item.title }}
                        </div>
                        <img class="plus" src="@/assets/icons/minus.jpg" v-if="item.isOpen" />
                        <img class="plus" src="@/assets/icons/plus.jpg" v-else />
                    </div>
                    <div v-if="item.isOpen" class="parameters__block__bot__in__bot">
                        <div class="parameter" v-for="(parameter, index) in item.specifications" :key="parameter" :class="{ 'even-background': index % 2 === 0 }">
                            <div class="parameter__first">{{ parameter.title }}</div>
                            <div class="parameter__second" v-if="parameter.value_for_1">{{ parameter.value_for_1 }}</div>
                            <div class="parameter__third" v-if="parameter.value_for_2">{{ parameter.value_for_2 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="price">
            <button @click="downloadPDF">
                Прайс лист
            </button>
            <button @click="downloadPDFb">
                Брошюра
            </button>
        </div>
    </div>
    <div class="mobile">
    <button class="openparameters">Параметры Модели</button>
    <div class="parametermobileback" v-if="model">
        <div class="parametermobile">
            <div class="parametermobile__top">
                <div class="parametermobile__top__left">
                    <p>Модель</p>
                </div>
                <div class="lineparameter"></div>
                <div class="parametermobile__top__right">
                    <p>{{ selectedParam }}</p>
                </div>
            </div>  
            <div class="parametermobile__block2">
                <p>Параметры модели</p>
            </div>
            <div class="lineparameter1"></div>
            <div class="parametermobile__block3" @click="openParam = !openParam">
                <div class="parametermobile__block3__top">
                    <p>{{ selectedParam }}</p>
                    <img src="@/assets/icons/arrow.png"/>
                </div>
                <div class="parametermobile__block3__bot" v-if="openParam">
                    <div v-for="(item ,index) in model.complectations" :key="index" class="parametermobile__block3__bot__1" :class="{ 'even-background1': index % 2 === 0 }">
                        <p @click="selectedParam = item.title">{{ item.title }}</p>
                    </div>
                </div>
            </div>
            <div class="parametermobile__block4">
                <div class="parametermobile__block4__in" v-for="item in model.specificationCategories" :key="item">
                    <div class="parametermobile__block4__in__top" @click="toggleParametermobile(item)">
                        <div class="parametmobile__block4__in__top__name">
                            <p>{{ item.title }}</p>
                        </div>
                        <img src="@/assets/icons/minus.jpg" v-if="item.isOpenmobile"/>
                        <img src="@/assets/icons/plus.jpg" v-else/>
                    </div>
                    <div class="parametermobile__block4__in__bot" v-if="item.isOpenmobile">
                        <div class="paramOpen" v-if="selectedParam == model.complectations[0].title" v-for="(parameter, index) in item.specifications" :key="parameter" :class="{ 'even-background': index % 2 === 0 }">
                            <div class="parametermobile__block4__in__bot">{{ parameter.title }}</div>
                            <div class="parametermobile__block4__in__bot" v-if="parameter.value_for_1">{{ parameter.value_for_1 }}</div>
                        </div>
                        <div v-if="model.complectations[1]">
                        <div class="paramOpen" v-if="selectedParam == model.complectations[1].title" v-for="(parameter,index) in item.specifications" :key="parameter" :class="{ 'even-background': index % 2 === 0 }">
                            <div class="parametermobile__block4__in__bot">{{ parameter.title }}</div>
                            <div class="parametermobile__block4__in__bot" v-if="parameter.value_for_2">{{ parameter.value_for_2 }}</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pricemobile" v-if="model">
            <button @click="downloadPDF">
                Прайс лист
            </button>
            <button @click="downloadPDFb">
                Брошюра
            </button>
    </div>
</div>
    <div class="main" v-if="model">
        <div class="main__title">
            ОСНОВНЫЕ МОМЕНТЫ
        </div>
        <template v-for="item in model.sections" :key="item.id">
            <img v-if="item.image" class="imgformain" :src="item.image" style="width: 100%;" />
            <div class="main__block1">
                <div class="main__block1__card" v-for="elem in item.sectionItems" :key="elem">
                    <img v-if="elem.image" :src="elem.image" />
                    <div class="main__block1__card__bot">
                        <p class="main__block1__card__bot__title">{{ elem.title }}</p>
                        <p class="main__block1__card__bot__subtitle">{{ elem.text}}</p>
                    </div>
                </div>
            </div>
        </template>
        <!-- <img class="imgformain" :src="model.sections[1].image" style="width: 100%;" />
        <div class="main__block2">
            <div class="main__block2__card" v-for="item in  model.sections[1].sectionItems" :key="item">
                <img :src="item.image" />
                <div class="main__block2__card__bot">
                    <p class="main__block2__card__bot__title">{{ item.title }}</p>
                    <p class="main__block2__card__bot__subtitle">{{ item.text }}</p>
                </div>
            </div>
        </div> -->
        <div class="info">
            <div class="info__image">
                <swiper ref="swiper" :slides-per-view="1" :thumbs="{ swiper: thumbsSwiper }" :modules="modules"
                    :speed="400">
                    <swiper-slide v-for="item in model.slider" :key="item" class="info__image-main">
                        <img :src="item.image" />
                    </swiper-slide>
                </swiper>
                <div class="info__image__swiper">
                    <swiper ref="swiper1" :speed="550" :modules="modules" :navigation="{
                        prevEl: '.arrow1block',
                        nextEl: '.arrow2block',
                        disabledClass: 'disabled'
                    }" :breakpoints="swiper.breakpoint2" @swiper="setThumbsSwiper"
                        @slideChange="setThumbsSwiper">
                        <swiper-slide v-for="item in model.slider" :key="item" class="info__image__swiper__image">
                            <img :src="item.image" />
                        </swiper-slide>
                    </swiper>
                    <div class="arrow1block">
                        <img src="@/assets/icons/arrow1.svg" />
                    </div>
                    <div class="arrow2block">
                        <img src="@/assets/icons/arrow2.svg" />
                    </div>
                </div>
            </div>
        </div>
        <test-drive id="test" :modalTitle="modalTitle"/>
    </div>
</template>
<script>
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { mask } from "vue-the-mask";

import SwiperCore, { A11y, Autoplay } from "swiper";
import { Thumbs, FreeMode, EffectFade } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
import TestDrive from '@/components/models/testDrive.vue';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

export default {
    directives: { mask },
    components: {
        Swiper, SwiperSlide,
        TestDrive
    },
    data() {
        return {
            modalTitle: 'Тест-драйв',
            selectedParam: '',
            openParam:false,
            swiper: {
                breakpoint2: {
                    350: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    },
                    1440: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    }
                },
            },
            modules: [Thumbs, FreeMode, EffectFade],
            thumbsSwiper: null,
            images: [
                {
                    name: '1'
                },
                {
                    name: '2'
                },
                {
                    name: '3'
                },
                {
                    name: '4'
                },
                {
                    name: '5'
                },
                {
                    name: '6'
                },

            ],
            block1: [
                {
                    img: "1",
                    title: "Гибридная система",
                    subtitle: "Первая саморазработанная гибридная платформа Hongqi, со смышленым сочетанием бензиновой и электрической энергии, обеспечивает более быстрое разгонное ускорение и меньший расход топлива, достигая мощной производительности и высокой энергоэффективности."
                },
                {
                    img: "2",
                    title: "Панорамный люк",
                    subtitle: "Приняв во внимание ведущий в своем классе сверхбольшой люк размером 1010 мм х 890 мм, это обеспечивает бесконечный и широкий обзор, сопровождающий солнечный свет днем и звездное небо ночью."
                },
                {
                    img: "3",
                    title: "Удобные сиденья",
                    subtitle: "Оборудованные передовыми комфортабельными сиденьями с четырехпозиционной пневматической поддержкой поясницы и восьмипозиционным пневматическим массажем, эффективно снимающим усталость у водителей и пассажиров."
                },

            ],
            block2: [
                {
                    img: "1",
                    title: "Интеллектуальная экосистема",
                    subtitle: "Система Qisi 1.0, разработанная независимо, в сочетании с интеллектуальными антеннами и технологиями 5G и Wi-Fi6 в автомобиле, обеспечивает беспрецедентную скорость представления контента экосистемы. Обеспечивая полное покрытие семи основных сценариев и безшовное соединение между автомобилем и мобильными устройствами, она предоставляет пользователям эффективный, всесторонний и беззаботный опыт интеллектуальной экосистемы."
                },
                {
                    img: "2",
                    title: "Панорамный люк",
                    subtitle: "Передавая классически пропорции гармонии Hongqi, дизайн автомобиля полностью выражает красоту сочетания квадратных и круглых форм. Он символизирует тысячелетнюю цивилизацию измерения времени, восхваляя двенадцать поперечных планок решетки, представляющих двенадцать земных ветвей. Линия бока с высоким передом и низким задом создает мощную атмосферу 'пробивания через волны и достижения моря' с немного наклоненной вперед позой."
                },
                {
                    img: "3",
                    title: "Исключительное здоровье, интеллектуальная свежесть.",
                    subtitle: "Применение восьми низколетучих и низкозапаховых материалов, дополненных параметрами VOC (органические соединения, вызывающие испарение) значительно превышающими международные требования, в сочетании с системой отрицательных ионов и очистки воздуха от PM2.5 (частиц размером менее 2,5 микрометров), обеспечивает строгий контроль качества воздуха, позволяя водителям и пассажирам наслаждаться свежим воздухом на протяжении всей поездки."
                },

            ],
            cars: [
                {
                    img: "black",
                    imgcolor: "colorblack"
                },
                {
                    img: "grey",
                    imgcolor: "colorgrey"
                },
                {
                    img: "white",
                    imgcolor: "colorwhite"
                }
            ],
            parameters: [
                {
                    name: "Основные параметры",
                    characteristik: [
                        {
                            first: "Длина х ширина х высота (мм)",
                            second: "4760x1907x1700	",
                            third: "4760x1907x1700",
                            color: "#FFF"
                        },
                        {
                            first: "Снаряженная масса",
                            second: "1795",
                            third: "1795",
                            color: "#ccc"
                        },
                        {
                            first: "Максимально допустимая масса (кг)",
                            second: "2245",
                            third: "2245",
                            color: "#FFF"
                        },
                        {
                            first: "Колесная база (мм)",
                            second: "2870",
                            third: "2870",
                            color: "#ccc"
                        },
                        {
                            first: "Тип двигателя",
                            second: "1989 куб.см, 4 цилиндра, рядный, с турбонаддувом",
                            third: "1989 куб.см, 4 цилиндра, рядный, с турбонаддувом",
                            color: "#FFF"
                        },
                    ]

                },
                {
                    name: "Внешние особенности",
                    characteristik: [
                        {
                            first: "Приветственный свет 'Крылья Хончи'",
                            img1: "yes",
                            img2: "yes",
                            color: "#FFF"
                        },
                        {
                            first: "Интеллектуальное управление дальним светом (IHC)",
                            img1: "no",
                            img2: "yes",
                            color: "#ccc"
                        },
                        {
                            first: "Автоматическое выравнивание фар",
                            img1: "yes",
                            img2: "yes",
                            color: "#FFF"
                        },
                        {
                            first: "Наружные зеркала с электроприводом и подогревом",
                            img1: "yes",
                            img2: "yes",
                            color: "#ccc"
                        },
                        {
                            first: "Накладки на пороги со светодиодной подсветкой",
                            img1: "yes",
                            img2: "no",
                            color: "#FFF"
                        },
                    ]
                },
                {
                    name: "Особенности интерьера",
                    characteristik: [
                        {
                            first: "Сиденья с отделкой кожей Nappa + Alcantara",
                            img1: "yes",
                            img2: "yes",
                            color: "#FFF"
                        },
                        {
                            first: "Сиденье водителя с электрической регулировкой по 12 направлениям",
                            img1: "no",
                            img2: "yes",
                            color: "#ccc"
                        },
                        {
                            first: "Система запоминания параметров сиденья водителя",
                            img1: "no",
                            img2: "yes",
                            color: "#FFF"
                        },
                        {
                            first: "Датчик качества воздуха (AQS)",
                            img1: "yes",
                            img2: "yes",
                            color: "#ccc"
                        },
                        {
                            first: "Высокоэффективный воздушный фильтр для твердых частиц PM2.5/фильтр пыльцы",
                            img1: "yes",
                            img2: "no",
                            color: "#FFF"
                        },
                    ]
                },
                {
                    name: "Удобные и безопасные функции",
                    characteristik: [
                        {
                            first: "Система старт/стоп",
                            img1: "yes",
                            img2: "yes",
                            color: "#FFF"
                        },
                        {
                            first: "Доступ без использования ключа",
                            img1: "yes",
                            img2: "yes",
                            color: "#ccc"
                        },
                        {
                            first: "Подушка безопасности водителя",
                            img1: "yes",
                            img2: "yes",
                            color: "#FFF"
                        },
                        {
                            first: "Электронный стояночный тормоз (EPB)",
                            img1: "yes",
                            img2: "yes",
                            color: "#ccc"
                        },
                        {
                            first: "Электронная система динамической стабилизации автомобиля (ESP)",
                            img1: "yes",
                            img2: "yes",
                            color: "#FFF"
                        },
                    ]
                },
            ],
            activeCarIndex: 0,
            model: false
        }
    },
    async created() {
        this.getPage()    
    },
    watch: {
        $route() {
            this.model.model.video = false
            this.getPage()
        }
    },
    methods: {
        async getPage() {            
            await this.$axios.get(`/model/get?slug=${this.$route.params.slug}&lang=ru`)
            .then(res => {
                this.model = res.data
                this.selectedParam = res.data.complectations[0].title
            })
            .catch(err => {
            })
        },
        formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        selectCar(index) {
            this.activeCarIndex = index;
        },
        toggleParameter(value) {
            value.isOpen = !value.isOpen;
        },
        toggleParametermobile(value){
            value.isOpenmobile = !value.isOpenmobile;
        },
        setThumbsSwiper(swiper) {
            console.log('swiper', swiper);
            this.thumbsSwiper = swiper;
            console.log(this.thumbsSwiper);
            console.log(swiper);
        },
        downloadPDF() {
            const link = document.createElement('a');
            link.href = this.model.model.price_list; // Путь к вашему PDF-файлу
            link.download = this.model.model.title; // Имя файла, каким он будет скачан
            link.target = '_blank'; // Откроется в новой вкладке
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        downloadPDFb(){
            const link = document.createElement('a');
            link.href = this.model.model.document; // Путь к вашему PDF-файлу
            link.download = this.model.model.title; // Имя файла, каким он будет скачан
            link.target = '_blank'; // Откроется в новой вкладке
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>
<style lang="scss" scoped>
.even-background1{
    background: grey !important;
}
.even-background{
    background: white !important;
}
.plus{
    width: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
    height: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
}
.pricemobile{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    button{
        font-size: 15px;
        border-radius: 50px;
        color: white;
        border: none;
        cursor: pointer;
        background: #42373c;
        width: 40%;
        padding: 15px 0px;
    }
}
.parametermobile{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    padding: 0px 20px;
    &__top{
        display: flex;
        align-items: center;
        color: white;
        &__left{
            width: 50%;
            padding: 20px 0px;
            text-align: center;
            background: #333;
        }
        &__right{
            width: 50%;
            padding: 20px 0px;
            text-align: center;
            background: #333;
        }
        .lineparameter{
            border: 1px solid white;
            height: auto;
        }
    }
    &__block2{
        width: 100%;
        text-align: center;
        padding: 10px 0px;
        border: 1px solid grey
    }
    &__block3{
        width: 100%;
        border: 1px solid grey;
        p{
            padding-left: 20px;
        }
        &__top{
            display: flex;
            padding: 10px 20px 10px 0px;
            justify-content: space-between;
            align-items: center;
            img{
                width:22px;
                height:22px;
            }
        }
        &__bot{
            display: flex;
            flex-direction:column;
            color: white;
            &__1{
                background: black;
                padding: 12px;
            }
        }
    }
    &__block4{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        &__in{
            padding: 10px;
            background-color: #efefef;
            border-bottom: 1px solid #fff;
            &__top{
                display: flex;
                justify-content:space-between;
                align-items: center;
            }
        }
    }
    .paramOpen{
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        box-sizing: border-box;
        background: #ccc;
        font-size: 12px;
        gap: 10px;

    }
}
.openparameters{
    margin-top: 30px;
    border-radius: 15px;
    font-size: 15px;
    width: 50%;
    height: 40px;
    border: none;
    background: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-transform: uppercase;
}
.price {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 50px;

    button {
        cursor: pointer;
        padding: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.6696))), 15px) min(max(54px, calc(3.375rem + ((1vw - 10.24px) * 4.0179))), 90px);
        font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.8929))), 24px);
        border: none;
        background: #42373c;
        border-radius: 50px;
        color: white;

        &:hover {
            background: red;
            transition: all 0.5s ease;
            transform: scale(1.1);
            color: white;
        }
    }
}

.imgformain {
    @media(max-width:867px) {
        display: none;
    }
}

.parameter {
    display: flex;
    padding: 20px;
    align-items: center;
    background: #ccc;
    justify-content: space-between;

    &__first {
        width: 20%;
        text-align: center;
    }

    &__second {
        width: 40%;
        text-align: center;
    }

    &__third {
        width: 40%;
        text-align: center;
    }

    &__img1 {
        width: 40%;
        text-align: center;
    }

    &__img2 {
        width: 40%;
        text-align: center;
    }
}

.imgcolor {
    width: 80px;
    height: 80px;

    @media(max-width:867px) {
        width: 40px;
        height: 40px;
    }

}

.param {
    display: flex;
    flex-direction: column;
    gap: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 2.2321))), 40px);
    &__title {
        text-align: center;
        margin-top:min(max(15px, calc(0.9375rem + ((1vw - 10.24px) * 3.9063))), 50px);
        font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 2.2321))), 40px);
        color: #282828;
        font-weight: 700;

        @media(max-width:867px) {
            display: none;
        }
    }

    &__main {
        display: flex;
        justify-content: space-between;
        padding: 0px 200px 80px 200px;
        @media(max-width:1710px) {
            padding: 0px 100px;
            padding-bottom: 50px;
        }
        @media(max-width:1400px) {
            padding: 0px 10px;
            padding-bottom: 50px;
    }

        @media(max-width:867px) {
            padding: 20px;
            flex-wrap: wrap;
        }

        &__left {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 10.24px) * 2.7902))), 50px);
            @media(max-width:1024px){
                gap: 10px;
            }
            @media(max-width:867px) {
                gap: 20px;
                width: 30%;
            }

            .character {
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 1.1161))), 20px);
                color: #525252;

                @media(max-width:867px) {
                    font-size: 14px;
                }
            }

            .character__info {
                color: #17181a;
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 10.24px) * 1.6741))), 30px);
                font-weight: 600;

                @media(max-width:867px) {
                    font-size: 18px;
                }
            }
        }
    }
}

.line {
    border: 1.5px solid black;

    @media(max-width:867px) {
        display: none;
    }
}

.active-link {
    color: red !important;
    transform: scale(1.8);
}

.hat {
    margin-top: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 6.6964))), 120px);
    display: flex;
    padding: 30px 0px;
    justify-content: space-between;
    align-items: center;
    background: black;
    opacity: 0.9;
    color: white;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
    font-weight: 600;

    @media(max-width:867px) {
        margin-top: 75px;
        font-size: 14px;
    }

    p {
        cursor: pointer;

        &:hover {
            color: red;
            transform: scale(1.2);
            transition: all 0.3s ease;
        }
    }

    &__title {
        display: flex;
        gap: 40px;
    }

    .hatline {
        width: 40%;
        height: 2px;
        background: #5a5a5a;
        @media(max-width:1400px){
            width: 30%;
        }
        @media(max-width:1100px){
            width: 30%;
        }
        @media(max-width:867px) {
            width: 10%;
        }
    }
}

.arrow1block {
    background-color: white;
    width: 1.5%;
    opacity: 0.6;
    position: absolute;
    z-index: 99;
    top: 28%;
    padding: 20px;

    @media(max-width:867px) {
        width: 1%;
        padding: 15px;
    }

    img {
        width: 30px;
        height: 30px;

        @media(max-width:867px) {
            width: 20px;
            height: 20px;
        }

    }
}

.arrow2block {
    padding: 20px;
    background-color: white;
    width: 1.5%;
    opacity: 0.6;
    position: absolute;
    z-index: 99;
    top: 28%;
    right: 1%;

    @media(max-width:867px) {
        width: 1%;
        padding: 15px;
    }

    img {
        width: 30px;
        height: 30px;

        @media(max-width:867px) {
            width: 20px;
            height: 20px;
        }
    }
}

.info {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        // gap: 20px;
    }

    &__image {
        &-main {
            overflow: hidden;
            height: 100%;
            border-radius: 0px;

            @media screen and (max-width: 768px) {}

            img {
                width: 100%;
                height: 100vh;
                object-fit: cover;
                object-position: bottom;

                @media(max-width:867px) {
                    height: 40vh;
                }
            }
        }

        &__swiper {
            margin-top: 0px;
            background: #000;
            position: relative;

            &__image {
                height: 200px;
                width: 200px;
                overflow: hidden;
                border-radius: 0px;
                cursor: pointer;
                opacity: 0.6;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .swiper-slide {
                cursor: pointer;

                &-thumb-active {
                    opacity: 1.5;
                }
            }
        }
    }
}

.parameters {
    padding-top: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 4.4643))), 100px);
    display: flex;
    flex-direction: column;
    gap: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 4.4643))), 100px);

    @media(max-width:867px) {
        display: none;
    }

    &__title {
        text-align: center;
        font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
        color: #000000;

        &__price {
            color: green;
            font-size: 32px;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        box-shadow: 10px 10px 10px #eee;

        &__top {
            display: flex;
            font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 1.5625))), 30px);
            justify-content: space-between;
            color: white;
            div {
                height: 80px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                &:first-child {
                    background-color: #686868;
                    flex-grow: 1;
                }
                &:last-child {
                    background-color: #333;
                    width: 50%;

                }
            }
        }

        &__bot {
            display: flex;
            flex-direction: column;
            color: #282828;

            &__in {
                background-color: #efefef;
                border-bottom: 1px solid #fff;
                padding: 5px 70px;

                &__top {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: space-between;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                    font-weight: 700;
                    color: #282828;

                    &__name {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.selected-img {
    display: flex;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 10.24px) * 3.9063))), 50px);
    align-items: center;
    justify-content: center;
    padding-top: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 4.4643))), 70px);
    @media(max-width:867px) {
        gap: 10px;
        padding-top: 30px;
    }

    img {
        border-radius: 50%;
        width: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 3.3482))), 70px);;
        height: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 3.3482))), 70px);;

        @media(max-width:867px) {
            width: 100%;
            height: 100%;
        }
    }
}

.selected-car {
    padding-top:  14%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 55%
    }
    @media(max-width:867px) {
        padding-top: 180px;
        img {
            width: 90%;
        }
    }
}

.main {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media(max-width:867px) {
        padding-top: 50px;
    }

    &__title {
        text-align: center;
        font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 2.2321))), 40px);
        color: #000000;

        @media(max-width:867px) {
            font-size: 32px;
        }
    }

    &__block1 {
        display: flex;
        padding: 0px 20px;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        gap: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 3.3482))), 50px);

        @media(max-width:867px) {
            gap: 0px;
        }

        &__card {
            flex: 1;
            display: flex;
            flex-direction: column;
            @media(max-width:1400px){
            width: 47%;
        }
            @media(max-width:867px) {
                width: 100%;
            }

            img {
                width: 100%;
                height: min(max(300px, calc(18.75rem + ((1vw - 10.24px) * 16.7411))), 450px);
                @media(max-width:867px){
                       height: 400px;
                    }
                    @media(max-width:767px){
                       height: 250px;
                    }
            }

            &__bot {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: #e0e0e0;
                flex-grow: 1;
                &__title {
                    color: #2e2e2e;
                    font-size: 1vw;
                    font-weight: 700;

                    @media(max-width:867px) {
                        font-size: 18px;
                    }
                }

                &__subtitle {
                    color: #646464;
                    font-size: 1vw;
                    font-weight: 500;

                    @media(max-width:867px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &__block2 {
        display: flex;
        padding: 0px 20px;
        flex-wrap: wrap;
        gap: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 3.3482))), 50px);
        align-items: stretch;
        @media(max-width:867px) {
            gap: 0px;
        }

        &__card {
            width: 31%;
            display: flex;
            flex-direction: column;
            @media(max-width:1400px){
            width: 47%;
        }
            @media(max-width:867px) {
                width: 100%;
            }

            img {
                width: 100%;
                height: min(max(300px, calc(18.75rem + ((1vw - 10.24px) * 16.7411))), 450px);

                @media(max-width:867px){
                       height: 400px;
                    }
                    @media(max-width:767px){
                       height: 250px;
                    }
            }

            &__bot {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: #e0e0e0;
                height: fit-content;
                flex-grow: 1;
                &__title {
                    color: #2e2e2e;
                    font-size: 1vw;
                    font-weight: 700;

                    @media(max-width:867px) {
                        font-size: 18px;
                    }
                }

                &__subtitle {
                    color: #646464;
                    font-size: 1vw;
                    font-weight: 500;

                    @media(max-width:867px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.cars{
    background-image: url('@/assets/images/carsback.jpg');
    background-size: 100% 100%;
    height: fit-content;
    @media(max-width:867px){
        background-size: 100% 100%;
  }
}

.video-background {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Настройте высоту видео под ваши нужды */
    overflow: hidden;

    @media(max-width:867px) {
        height: 30vh;
    }
}

.custom-text {
    position: absolute;
    bottom: 50px;
    left: 50px;
    font-size: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 4.4643))), 80px);
    color: white;

    @media(max-width:867px) {
        left: 1%;
        bottom: 1%;
        font-size: 30px;
    }

}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Обрезает видео, чтобы оно занимало весь контейнер */
}

.custom-button {
    position: absolute;
    bottom: 60px;
    right: 60px;
    padding: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px) min(max(32px, calc(2rem + ((1vw - 10.24px) * 3.125))), 60px);
    background-color: #42373c;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: pointer;

    @media(max-width:867px) {
        right: 3%;
        bottom: 3%;
        font-size: 30px;
        padding: 10px;
        font-size: 15px;
    }
}</style>