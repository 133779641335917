<template>
    <div class="model">
    <div class="desctop">
        <img src="@/assets/images/modelback.jpg" class="mainimg"/>
    </div>
    <div class="mobile">
        <img src="@/assets/images/modelbackmobile.jpg" class="mainimg1"/>
    </div>
        <p class="model__title">МОДЕЛИ</p>
        <div class="model__cards">
            <div class="model__cards__card" v-for="item in cards" :key="item">
                <div class="model__cards__card__title">
                    {{ item.name }}
                </div>
                <router-link :to="'/models/'+item.link"><img :src="require('@/assets/icons/Cars/' + item.img + '.png')"/></router-link>
                <router-link :to="'/models/'+item.link"><button>Подробности</button></router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            cards: [
                {
                    name: "H9",
                    img: "h9",
                    link: "h9"
                },
                {
                    name: "E-HS9",
                    img: "ehs",
                    link: "e-hs9"
                },
                {
                    name: "H5",
                    img: "h5",
                    link: "h5"
                },
                {
                    name: "HS5",
                    img: "hs5",
                    link: "hs5"
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .model{
        display: flex;
        flex-direction: column;
        gap: 80px;
        padding-bottom: 100px;
        justify-content: center;
        align-items: center;
        @media(max-width:867px){
                   gap: 40px; 
        }
        .mainimg{
          padding-top: 120px;
          @media(max-width:1710px){
              padding-top: 80px;
            }
            @media(max-width:1400px){
              padding-top: 70px;
              width: 100%;
            }
            
        }
        .mainimg1{
            padding-top: 75px;
            width: 100%;
            object-fit: cover;
        }
        &__title{
            text-align: center;
            font-size: min(max(32px, calc(2rem + ((1vw - 10.24px) * 3.125))), 60px);
            color: #282828;
            @media(max-width:867px){
            font-size: 30px;
          }
            
        }
        &__cards{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.1161))), 50px);
            row-gap: min(max(80px, calc(5rem + ((1vw - 10.24px) * 2.2321))), 100px);
            @media(max-width:867px){
            flex-direction: column;
            gap: 60px;
          }
            &__card{
                width: 37%;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 4.9107))), 104px);
                font-weight: 600;
                color: #e3e3e3;
                gap: 30px;
                @media(max-width:867px){
                    width: 100%;
                    font-size: 45px;
                    gap: 10px;
                }
                a{
                img{
                    width: 100%;
                    height: min(max(90px, calc(5.625rem + ((1vw - 10.24px) * 6.6964))), 150px);
                    @media(max-width:867px){
                    height: 100px;
                     }
                }
            }
                button{
                    background-color: #484848;
                    cursor: pointer;
                    border-radius: 2vw;
                    color: #fff;
                    border: none;
                    padding: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.6696))), 15px) min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 3.9063))), 80px);
                    text-transform: uppercase;
                    font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
                    transition: all 0.5s ease;
                    @media(max-width:867px){
                        border-radius: 30px;
                         }
                    &:hover{
                        background: red;
                    }
                }
            }
        }
    }
</style>