<template>
  <div class="home">
    <div class="home__block1"  v-if="home">
      <div class="desctop">
      <swiper 
        :slidesPerView="1" 
        :pagination="{ 
                clickable: true, 
                type: 'bullets', 
                horizontalClass: 'bullets-content', 
                bulletClass: 'bulletsA', 
                bulletActiveClass: 'bulletsA-active', 
                el: '.paginationclass', // 
        }"
        :lazy="true" 
        :autoplay="{
          delay: 3000
        }"
        :loop="true"
        :navigation="{ 
                        prevEl: '.arrow1', 
                        nextEl: '.arrow2', 
                        disabledClass: 'disabled' 
                    }"
      > 
        <swiper-slide v-for="(item) in home.sliders" :key="item" > 
          <router-link :to="item.link"><div class="home__block1__cards" :style="{ backgroundImage: 'url(' + item.image + ')'}">
          <div class="home__block1__cards__arrows">
            <img src="@/assets/icons/prev.svg" class="arrow1"/>
            <img src="@/assets/icons/next.svg" class="arrow2"/>
          </div>
          </div>
        </router-link>
        </swiper-slide> 
      </swiper>
      <div class="paginationclass">

      </div>
    </div>
    <div class="mobile">
      <swiper 
        :slidesPerView="1" 
        :pagination="{ 
                      clickable: true, 
        }"
        :lazy="true" 
        :loop="true"
        :navigation="{ 
                        prevEl: '.arrow1', 
                        nextEl: '.arrow2', 
                        disabledClass: 'disabled' 
                    }"
      > 
        <swiper-slide v-for="(item) in home.sliders" :key="item" > 
          <router-link :to="item.link"><div class="mobilehome__block1__cards" :style="{ backgroundImage: 'url(' + item.image_mob + ')'}">
          <div class="mobilehome__block1__cards__arrows">
          </div>
          </div></router-link>
        </swiper-slide> 
      </swiper>
    </div>
    </div>
    <div class="home__block2" v-if="home">
      <div class="home__block2__title">
        <p>МОДЕЛИ hongqi</p>
      </div>
      <div class="home__block2__names">
        <div v-for="section in home.types" :key="section.title" @click="selectSection(section.title)" :class="{ active: section.title === selectedSection }">
          {{ section.title }}
          <div class="triangle" v-show="section.title === selectedSection"></div>
        </div>
      </div>
      <div class="home__block2__cars">
        <div v-for="car in filteredCars" :key="car.title" class="home__block2__cars__in" >
          <div class="home__block2__cars__image" @click="selectCar(car.title)" :class="{ active: car.title === selectedCar }">
            <img :src="car.logo"/>
          </div>
          <div @click="selectCar(car.title)" :class="{ active: car.title === selectedCar }">
          {{ car.title }}
          <div class="active-line" v-show="car.title === selectedCar"></div>
        </div>
        </div>
      </div>
    </div>
      <swiper
      @slideChange="onSlideChange"
      :slidesPerView="1" 
        :navigation="{ 
                        prevEl: '.arrow1', 
                        nextEl: '.arrow2', 
                        disabledClass: 'disabled' 
                    }"
          @swiper="onSwiper"
      >
      <swiper-slide v-for="car in allCars" :key="car.id">
        <div class="home__blockcar ">
          <router-link :to="'/models/'+car.slug" style="text-decoration: none; color: inherit;"><div class="home__blockcar__top">
          <div class="home__blockcar__top__left">
            <p class="home__blockcar__top__left__title">{{car.title}}</p>
            <router-link to="/test">
            <button class="home__blockcar__top__left__button">
              ТЕСТ-ДРАЙВ
            </button>
          </router-link>
          </div>
          <div class="home__blockcar__top__right" >
            <!-- <div v-if="seletedCarActive == color.id"> -->
              <img :src="selectedCarImg"/>
          <!-- </div> -->
          </div>
        </div>
        </router-link>
        <div class="home__blockcar__bot">
          <div class="home__blockcar__bot__box" v-for="color in car.colors" :key="color">
            <img
                class="color-img"
                :src="color.hex"
                :data-color="color.title"
                :data-carimg="color.image"
                @click="selectColor(color)"
              />
          </div>
        </div>
      </div>
      </swiper-slide>
    </swiper>
    <div class="home__block3background">
      <div class="home__block3">
        <div class="home__block3__title">
          <p>МИР HONGQI</p>
        </div>
        <div class="home__block3__cards">
          <div class="home__block3__cards__top">
            <div class="home__block3__cards__top__1">
              <p class="home__block3__cards__top__1__title">История</p>
              <button class="home__block3__cards__top__1__button">
                <router-link to="/history" style="text-decoration: none; color: inherit;"><p>Подробнее</p></router-link>
              </button>
            </div>
            <div class="home__block3__cards__top__2">
              <p class="home__block3__cards__top__2__title">Бренд</p>
              <button class="home__block3__cards__top__2__button">
                <router-link to="/brand" style="text-decoration: none; color: inherit;"><p>Подробнее</p></router-link>
              </button>
            </div>
          </div>
          <div class="home__block3__cards__bot">
            <div class="home__block3__cards__bot__1"> 
              <p class="home__block3__cards__bot__1__title">
                Исследования и разработки
              </p>
              <button class="home__block3__cards__bot__1__button"><router-link to="/develop" style="text-decoration: none; color: inherit;"><p>Подробнее</p></router-link></button>
            </div>
            <div class="home__block3__cards__bot__2"> 
              <p class="home__block3__cards__bot__2__title">
                Достижения
              </p>
              <button class="home__block3__cards__bot__2__button"><router-link to="/achievement" style="text-decoration: none; color: inherit;"><p>Подробнее</p></router-link></button>
            </div>
            <div class="home__block3__cards__bot__3"> 
              <p class="home__block3__cards__bot__3__title">
                Социальная ответственность
              </p>
              <button class="home__block3__cards__bot__3__button"><router-link to="/social" style="text-decoration: none; color: inherit;"><p>Подробнее</p></router-link></button>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="home__block4">
        <div class="home__block4__title">
          <p>НОВОСТИ</p>
        </div>
        <div class="home__block4__cards" v-if="news.length">
        <div class="home__block4__cards__card" v-for="item in news" :key="item">
              <div class="home__block4__cards__card__image"  @click="$router.push('/news/' + item.slug)"> 
                <img :src="item.image"/>
              </div>
              <div class="home__block4__cards__card__title">
                {{ item.title }}
              </div>
              <div class="lineblock4"></div>
              <div class="home__block4__cards__card__bot">
                <div class="home__block4__cards__card__bot__date">
                  {{ item.time }}
                </div>
                <div class="home__block4__cards__card__bot__button"><button @click="$router.push('/news/' + item.slug)">Подробнее</button>
                </div>
              </div>
            </div>
        </div>
      </div>
      <!-- <div class="home__block5 container">
        <div class="home__block5__title">
          <p>Interact</p>
        </div>
        <div class="home__block5__cards">
          <div class="home__block5__cards__card" v-for="item in block5cards" :key="item">
            <div class="home__block5__cards__card__top">
              <div class="home__block5__cards__card__top__left">
                <img src="@/assets/icons/logo.svg"/>
              </div>
              <div class="home__block5__cards__card__top__right">
                <p class="home__block5__cards__card__top__right__title">{{ item.title }}</p>
                <p class="home__block5__cards__card__top__right__subtitle">{{ item.subtitle }}</p>
                <p class="home__block5__cards__card__top__right__date">{{ item.date }}</p>
              </div>
            </div>
            <div class="home__block5__cards__card__image">
              <img :src="require('@/assets/images/' + item.img + '.png')"/>
            </div>
            <div class="home__block5__cards__card__bot">
              <div class="home__block5__cards__card__bot__comments">
                <img src="@/assets/icons/comments.svg"/>
                {{ item.comments }}
              </div>
              <div class="home__block5__cards__card__bot__likes">
                <img src="@/assets/icons/likes.svg"/>
                {{ item.likes }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import "swiper/swiper.min.css";
 
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export default {
  name: 'HomeView',
  data() {
    return {
      colored: "",
      home: null,
      imaged: '',
      selectedColor: "blackh9",
      selectedCarImg: "",
      seletedCarActive: 111,
      swiperIndex: 0,
      block5cards: [
        {
          img: "interact_1",
          title: "Hongqi",
          subtitle: "Breathe in the freshness of a forest oxygen bar right in #HONGQI #H9 with its air purification…",
          date: "08:53 AM·Jun 28,2023  ·  Twitter",
          comments: 0,
          likes: 32
        },
        {
          img: "interact_2",
          title: "Hongqi",
          subtitle: "With #HONGQI #E_HS9, no road is too challenging to conquer.",
          date: "08:52 AM·Jun 28,2023  ·  Twitter",
          comments: 0,
          likes: 39
        },
        {
          img: "interact_3",
          title: "Hongqi",
          subtitle: "#HONGQI #E001 The first car under the HONGQI New Energy Vehicle (NEV) brand comes with…",
          date: "08:51 AM·Jun 28,2023  ·  Twitter",
          comments: 0,
          likes: 31
        },
        
      ],
      block4cards: [
        {
          img: "events_1",
          title: "Шанхайская автомобильная выставка HONGQI | Коллекция моделей H9",
          date: "2023-06.29",
        },
        {
          img: "events_2",
          title: "Шанхайская автомобильная выставка HONGQI | яркие моменты моделей",
          date: "2023-04.19",
        },
        {
          img: "events_3",
          title: "Выставка автомобилей HONGQI в Шанхае  Коллекция концепт-каров",
          date: "2023-03.29",
        },
      ],
      block1mobilecards:[
            {
              image:"mobile1",
              link:"world"
            },
            {
              image:"mobile2",
              link:"h9"
            },
            {
              image:"mobile3",
              link:"e-hs9"
            },
            {
              image:"mobile4",
              link:"hs5"
            },
            {
              image:"mobile5",
              link:"h5"
            },
      ],
      block1cards: [
        {
          image: "img",
          link: "h5"
        },
        {
          image: "img1",
          link: "hs5"
        },
        {
          image: "img2",
          link: "e-hs9"
        },
        {
          image: "img3",
          link: "h9"
        },
        {
          image: "img4",
          link: "world"
        }
      ],
      sections: [
        {
          id: 1,
          name: 'sedan',
          cars: [
            {
              id: 11,
              image: "h9",
              name: "H9",
              link:"h9",
              colors: [
                {
                  id: 111,
                  name: "Black",
                  colorimg : "blackh9",
                  carimg: "blackh9car"
                },
                {
                  id: 112,
                  name: "Laser Blue",
                  colorimg : "purpleh9",
                  carimg: "purpleh9car"
                },
                {
                  id: 113,
                  name: "Purple",
                  colorimg : "whiteh9",
                  carimg: "whiteh9car"
                },
              ]
            },
            {
              id: 12,
              image: "h5",
              name: "H5",
              link:"h5",
              colors: [
                {
                  id: 115,
                  name: "H5",
                  colorimg : "white",
                  carimg: "whitecar"
                },
                {
                  id: 116,
                  name: "H5",
                  colorimg : "grey",
                  carimg: "greycar"
                },
                {
                  id: 117,
                  name: "H5",
                  colorimg : "black",
                  carimg: "blackcar"
                },
              ]
            },
          ]
        },
        {
          id: 2,
          name: 'suv',
          cars: [
            {
              id: 15,
              image: "hs5",
              name: "HS5",
              link:"hs5",
              colors: [
                {
                  id: 127,
                  name: "H5",
                  colorimg : "whitehs5",
                  carimg: "whitehs5car"
                },
                {
                  id: 128,
                  name: "H5",
                  colorimg : "blackhs5",
                  carimg: "blackhs5car"
                },
                {
                  id: 129,
                  name: "H5",
                  colorimg : "bluehs5",
                  carimg: "bluehs5car"
                },
                {
                  id: 130,
                  name: "H5",
                  colorimg : "greyhs5",
                  carimg: "greyhs5car"
                },
              ]
            }
          ]
        },
        {
          id: 3,
          name: 'ev',
          cars: [
            {
              id: 17,
              image: "ehs",
              name: "E-HS9",
              link:"e-hs9",
              colors: [
                {
                  id: 135,
                  name: "H5",
                  colorimg : "white",
                  carimg: "whiteehscar"
                },
                {
                  id: 136,
                  name: "H5",
                  colorimg : "blackh9",
                  carimg: "blackehscar"
                },
                {
                  id: 137,
                  name: "H5",
                  colorimg : "grey",
                  carimg: "greyehscar"
                },

              ]
            }
          ]
        }
      ],
      selectedSection: 'Sedan', // Изначально выбран раздел "sedan"
      selectedCar: 'H5',
      allCars: [],
      news:[]
      // Изначально не выбрана ни одна машина
      
    }
  },
  async created() {
    await this.$axios.get(`/home?lang=ru`)
    .then(res => {
      this.home = res.data      
      this.home.types.forEach(section => {
        this.allCars.push(...section.models);
      });
    })
    await this.$axios.get(`news`)
    .then(res => {
      this.news = res.data[0]
    })
    this.selectedCarImg = this.allCars[0].colors[0].image
  },
  methods: {

    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiperIndex = swiper.activeIndex;
    },
    selectSection(sectionName) {
      this.selectedSection = sectionName;
    },
    selectCar(value) {
      this.selectedCar = value;
  },
  onSwiper(swiper) {
      this.swiper = swiper;
    },
    selectColor(color) {
      this.selectedCarImg = color.image;
    },
    onSlideChange() {
      // Получаем индекс текущего активного слайда
      const activeSlideIndex = this.swiper.activeIndex;
      this.selectedCarImg = this.allCars[this.swiper.activeIndex].colors[0].image
      // if (activeSlideIndex === 1) {
      //   // Устанавливаем новое значение для selectedCarImg на втором слайде
      //   this.selectedCarImg = 'whitecar';
      //   // Другая логика, если необходимо
      // } else if (activeSlideIndex === 2) {
      //   // Устанавливаем новое значение для selectedCarImg на третьем слайде
      //   this.selectedCarImg = 'whitehs5car';
      //   // Другая логика, если необходимо
      // } else if (activeSlideIndex === 3) {
      //   // Устанавливаем новое значение для selectedCarImg на четвертом слайде
      //   this.selectedCarImg = 'whiteehscar';
      //   // Другая логика, если необходимо
      // } else if (activeSlideIndex === 0){
      //   this.selectedCarImg = 'blackh9car';
      // }
    },
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    selectedCarIndex() {
      return this.allCars.findIndex((car) => car.title === this.selectedCar);
    },
    filteredCars() {
      // Фильтрация машин на основе выбранного разделif (this.home.length) {}а
      let activeSection = []
      if (this.home) {
        activeSection = this.home.types.find(value => value.title === this.selectedSection).models;
      }
      return activeSection;
    }
  },
  watch: {
    selectedCarIndex(newIndex) {
      this.swiper.slideTo(newIndex);
    },
  },
}
</script>
<style lang="scss" scoped>
.mobilehome__block1__cards{
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 55px;

}
.active {
  /* Стили для активного раздела или машины */
  position: relative;
  font-weight: 800;
}
.home__block3background{
  background-color: #f3f3f3;
}
.active .active-line {
  /* Стили для линии активной машины */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

.triangle {
  /* Стили для маленького перевернутого треугольника */
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid black;
}
.color-img{
  border: 3px solid grey;
}
.lineblock4{
  border: 1px solid black;
}
.paginationclass{
  display: flex;
  gap: 10px;
  justify-content: center;
}
    .home{
      &__blockcar{
        display: flex;
        flex-direction: column;
        background-image: url('@/assets/images/backphone.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        @media (max-width: 867px)
            {
              background-image: url('@/assets/images/backphonemobile.jpg');
              background-size: 100% 330px;
              object-fit: cover;
            }
        @media (max-width: 767px)
            {
              background-image: url('@/assets/images/backphonemobile.jpg');
              background-size: 100% 350px;
              object-fit: cover;
            }
        &__top{
          display: flex;
        margin-left: 80px;
          box-sizing: border-box;
          justify-content: space-evenly;
          margin-top: 60px;
        @media (max-width: 867px){
            margin-top: 0px;
            gap: 0px;
            flex-direction: column;
            margin-left:50px;
          }
          @media (max-width: 767px){
            margin-top: 0px;
            gap: 0px;
            justify-content: flex-start;
            flex-direction: column;
            margin-left: 0px;
          }
          &__left{
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            width: 40%;
            gap: 20px;
            @media (max-width: 867px)
            {
              gap: 20px;
              margin-top: 0px;
              margin-left: auto;
              margin-right: auto;
              align-items: center;
              width: 100%;
            }
            &__title{
              color: #282828;
              font-size: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 5.4688))), 89px);;
              font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: uppercase;
              @media (max-width: 867px)
            {
              font-size: 60px;
            }
            }
            &__button{
              height: min(max(35px, calc(2.1875rem + ((1vw - 10.24px) * 5.0223))), 80px);;
              background: #333;
              border: none;
              color: #FFF;
              font-size: 17px;
              font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              width: 50%;
              @media (max-width:867px){
                padding: 0.6em 1.5em;
                font-size: 3.2vw;
                height: 40px;
                font-size: 12px;
                width: 100%;
              }
            }
          }
          &__right{
            width: 70%;
            height: 100%;
            img{
              width: 100%;
              z-index: 999;
              height: 100%;
              margin-top: 60px;
        @media (max-width: 867px)
            {
              height: 150px;
              width: 100%;
              margin-top: 30px !important;
              margin-left: 70px;
            }
              @media (max-width: 767px)
            {
              height: 100%;
              width: 100%;
              margin-top: 30px !important;
              margin-left: 55px;
            }

            }
          }
        }
        &__bot{
          display: flex;
          gap: 13px;
          margin-left: 80px;
          padding-bottom: 133px;
          @media(max-width:867px){
              margin-left: 40px;
              margin-right: auto;
              margin-top: 100px;
              padding-bottom: 50px;
          }
          &__box{
            img{
              width: min(max(48px, calc(3rem + ((1vw - 10.24px) * 3.5714))), 80px);
              height: min(max(48px, calc(3rem + ((1vw - 10.24px) * 3.5714))), 80px);
              @media(max-width:867px){
                height: 40px;
                width: 40px;
              }
            }
            p{
              color: #333;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-style: normal;
              width: 40px;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              text-align: center;
              @media(max-width:867px){
                font-size: 12px;
              }
            }
          }
        }
       
      }
      &__block1{
        &__cards{
          height: 90vh;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          @media(max-width:1400px){
              height: 85vh;
            }
            @media(max-width:1100px){
                height: 85vh;
            }
          &__button{
            padding-top: 345px;
            padding-left: 237px;
            @media (max-width: 867px)
            {
              padding-top: 109%;
              padding-left: 10%;
            }
            button{
            width: 162.03px;
            height: 49.56px;
            background: #2F3944;
            border: none;
            border-radius: 5px;
            color: white;
            font-weight: 800;
            font-size: 20px;
            @media (max-width: 867px)
            {
             width: 110px;
             height: 30px;
             font-size: 13px;
            }
            }
          }
          &__arrows{
            display: flex;
            justify-content: space-between;
            padding: 500px 80px;
            margin-top: auto;
            margin-bottom: auto;
            @media(max-width:1400px){
              display: none;
            }
            @media (max-width: 867px)
            
            {
              display: none;
            }
          }
        }
      }
      &__block2{
        &__title{
          color: #282828;
          font-weight: 700;
          font-size: 33px;
          text-transform: uppercase;
          text-align: center;
          @media(max-width:867px){
            font-size: 20px;
            margin-top: 20px;
          }
        }
        &__names{
          display: flex;
          gap: 120px;
          color: #282828;
          font-size: 18px;
          font-weight: 500;
          text-transform: uppercase;
          justify-content: center;
          padding-top: 60px;
          cursor: pointer;
          @media (max-width: 867px)
            {
              gap: 20px;
            }
        }
        &__cars{
          display: flex;
          gap: 200px;
          padding-top: 60px;
          padding-bottom: 60px;
          justify-content: center;
          @media (max-width: 867px)
            {
              gap: 20px;
              img{
                height: 100px;
                width: 180px;
              }
            }
          &__in{
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: 867px)
            {
              gap: 20px;
            }
            p{
              font-size: 18px;
              color: #282828;
              font-weight: 700;
            }
          }
        }
      }
      &__block3{
        display: flex;
        flex-direction: column;
        gap: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 3.9063))), 95px);
        padding-bottom: min(max(75px, calc(4.6875rem + ((1vw - 10.24px) * 4.4643))), 115px);
        @media(max-width:867px){
            gap: 20px;
            padding-bottom: 50px;
        }
        &__title{
          color: #282828;
          font-size: min(max(21px, calc(1.3125rem + ((1vw - 10.24px) * 1.3393))), 33px);
          font-weight: 700;
          text-align: center;
          padding-top: 50px;
          text-transform: uppercase;
          @media(max-width:867px){
            padding-top: 20px;
            font-size: 20px;
          }
        }
        &__cards{
          display: flex;
          flex-direction: column;
          gap: 28px;
          padding: 20px;
          padding-right: 20px;
          &__top{
            display: flex;
            gap: 28px;
            width: 100%;
            @media(max-width:867px){
              flex-direction: column;

            }
            &__1{
              background-image: url('@/assets/images/about_1.png');
              background-size: 100% 100%;
              background-position: center;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              &:hover{
                background-size: 105% 105%;
                &__button{
                  background: white !important;
                  color: black !important;
                }
              }
              width: 50%;
              height: min(max(300px, calc(18.75rem + ((1vw - 10.24px) * 16.7411))), 450px);
              display: flex;
              transition: all 0.5s ease;
              flex-direction: column;
              align-items: center;
              gap: 50px;
        @media(max-width:867px){
              width: 100%;
              height: 370px;
              background-size: cover;
              gap: 20px;
        }
            
              @media(max-width:767px){
              width: 100%;
              height: 230px;
              background-size: cover;
              gap: 20px;
            
            }
              &__title{
                color: white;
                text-align: center;
                padding-top: min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 5;
                text-transform: uppercase;
                @media(max-width:867px){
                  padding-top: 150px;
                  font-size: 19px;
                }
                @media(max-width:767px){
                  padding-top: 80px;
                  font-size: 19px;
                }
            
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                z-index: 4;
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
                
              }
            }
            &__2{
              background-image: url('@/assets/images/about_2.jpg');
              background-size: 100% 100%;
              background-position: center;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              &:hover{
                background-size: 105% 105%;
              }
              width: 50%;
              height:  min(max(300px, calc(18.75rem + ((1vw - 10.24px) * 16.7411))), 450px);
              display: flex;
              transition: all 0.5s ease;
              flex-direction: column;
              align-items: center;
              gap: 50px;
          @media(max-width:867px){
              width: 100%;
              height: 370px;
              background-size: cover;
              gap: 20px;
        }
              @media(max-width:767px){
              width: 100%;
              height: 230px;
              gap: 20px;
            }
              &__title{
                color: white;
                text-align: center;
                padding-top: min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                text-transform: uppercase;
                z-index: 3;
                @media(max-width:867px){
                  padding-top: 150px;
                  font-size: 19px;

                }
                @media(max-width:767px){
                  padding-top: 80px;
                  font-size: 19px;

                }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                z-index: 2;
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;

            }
              }
            }
          }
          &__bot{
            display: flex;
            gap: 28px;
            width: 100%;
            @media(max-width:867px){
              flex-direction: column;
            }
            &__1{
              background-image: url('@/assets/images/about_3.png');
              width: 33%;
              height: min(max(290px, calc(18.125rem + ((1vw - 10.24px) * 15.625))), 430px);
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 50px;
              background-size: 100% 100%;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              @media(max-width:867px){
              width: 100%;
              height: 370px;
              gap: 20px;
              
            }
            @media(max-width:767px){
              width: 100%;
              height: 230px;
              gap: 20px;
              
            }
              background-position: center;
              transition: all 0.5s ease;
              &:hover{
                background-size: 105% 105%;
              }
              &__title{
                color: white;
                text-align: center;
                padding-top: min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 3;
                text-transform: uppercase;
                @media(max-width:867px){
                  padding-top: 150px;
                  font-size: 19px;

            }
            @media(max-width:767px){
                  padding-top: 80px;
                  font-size: 19px;

            }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                z-index: 2;
                line-height: normal;
                text-transform: uppercase;
                @media(max-width:1100px){
              height: 50px;
          }
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
                
              }
            }
            &__2{
              background-image: url('@/assets/images/about_4.png');
              width: 33%;
              height: min(max(290px, calc(18.125rem + ((1vw - 10.24px) * 15.625))), 430px);
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 50px;
              background-size: 100% 100%;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              @media(max-width:867px){
              width: 100%;
              height: 370px;
              gap: 20px;
            }
            @media(max-width:767px){
              width: 100%;
              height: 230px;
              gap: 20px;
            }
            
              background-position: center;
              transition: all 0.5s ease;
              &:hover{
                background-size: 105% 105%;
              }
              &__title{
                color: white;
                text-align: center;
                padding-top: min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 3;
                text-transform: uppercase;
                @media(max-width:1100px){
                  padding-top: 120px;
                }
                @media(max-width:867px){
                  padding-top: 150px;
                  font-size: 19px;

            }
            @media(max-width:767px){
                  padding-top: 80px;
                  font-size: 19px;

            }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                z-index: 2;
                line-height: normal;
                text-transform: uppercase;
                @media(max-width:1100px){
              height: 50px;
          }
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
              }
            }
            &__3{
              background-image: url('@/assets/images/about_5.png');
              width: 33%;
              height: min(max(290px, calc(18.125rem + ((1vw - 10.24px) * 15.625))), 430px);
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 50px;
              background-size: 100% 100%;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              @media(max-width:867px){
              width: 100%;
              height: 370px;
              gap: 20px;
              }
              @media(max-width:767px){
              width: 100%;
              height: 230px;
              gap: 20px;
              }
              background-position: center;
              transition: all 0.5s ease;
              &:hover{
                background-size: 105% 105%;
              }
              &__title{
                color: rgb(255, 255, 255);
                text-align: center;
                padding-top: min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                text-transform: uppercase;
                z-index: 4;
                @media(max-width:1100px){
                  padding-top: 120px;
                }
                @media(max-width:867px){
                  padding-top: 50px;
                  font-size: 19px;

            }
            @media(max-width:767px){
                  padding-top: 80px;
                  font-size: 19px;

            }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #ffffff;
                font-weight: 700;
                line-height: normal;
                z-index: 3;
                text-transform: uppercase;
                @media(max-width:1100px){
              height: 50px;
          }
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
              }
            }
          }
        }
      }
      &__block4{
        display: flex;
        flex-direction: column;
        gap: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.6741))), 55px);
        padding-top: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.6741))), 55px);
        padding-bottom: min(max(70px, calc(4.375rem + ((1vw - 10.24px) * 3.3482))), 100px);
        font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
        @media(max-width:867px){
            gap: 20px;
            padding-top: 20px;
        }
        &__title{
          text-align: center;
          font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 1.6741))), 35px);
          font-weight: 700;
          line-height: normal;
          letter-spacing: 6.912px;
          text-transform: uppercase;
          color: #282828;
          @media(max-width:867px){
            font-size: 24px;
        }
        
        }
        &__cards{
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          gap: 32px;
          align-items: stretch;
          @media(max-width:1710px){
              padding: 20px;
            }
          @media(max-width:867px){
            flex-direction: column;
          }
          &__card{
            width: 32%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
            @media(max-width:1710px){
              width: 31.5%;
            }
            @media(max-width:1100px){
                  width: 30%;
                  gap: 10px;
                }
            @media(max-width:867px){
            width:100%;
            gap: 12px;
            padding: 10px 10px;
          }
            &__image{
              overflow: hidden;
              height: min(max(200px, calc(12.5rem + ((1vw - 10.24px) * 22.3214))), 400px);
              display: flex;
              align-items: center;
              justify-content: center;
              @media(max-width:867px){
              height: 350px;
            }
            @media(max-width:767px){
              height: 250px;
            }
              img{
                width: 100%;
                height: 100%;
              }
            }
            &__title{
              color: #333;
              font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.558))), 17px);
              font-style: normal;
              font-weight: 700;
              line-height: 38.4px;
              flex-grow: 1;
            }
            &__bot{
              display: flex;
              justify-content: space-between;
              align-items: center;
              &__date{
                color: #282828;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.4464))), 14px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              &__button{
                button{
                border: 3px solid #000;
                background: #FFF;
                transition: all 0.5s ease;
                &:hover{
                  background: #282828;
                  color: white;
                }
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.6786))), 69px);
                color: #282828;
                font-size: 15px;
                font-weight: 700;
                width: min(max(140px, calc(8.75rem + ((1vw - 10.24px) * 6.6964))), 200px);
                @media(max-width:867px){
                  height: 30px;
                  width: 130px;
                }
                }
              }
            }
          }
        }
      }
      &__block5{
        display: flex;
        flex-direction: column;
        gap: 100px;
        padding-bottom: 100px;
        &__title{
          color: #282828;
          text-align: center;
          font-size: 35px;
          font-family: Microsoft YaHei;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 6.912px;
          text-transform: uppercase;
        }
        &__cards{
          display: flex;
          flex-wrap: wrap;
          gap: 28px;
          @media(max-width:867px){
              flex-direction: column;
            }
          &__card{
            width: 32%;
            display: flex;
            flex-direction: column;
            transition: all 0.5s ease;
            @media(max-width:867px){
              width: 100%;
            }
            &:hover{
              -webkit-box-shadow: 11px 10px 62px 0px rgba(79, 136, 180, 0.3);
              -moz-box-shadow: 11px 10px 62px 0px rgba(79, 136, 180, 0.3);
              box-shadow: 11px 10px 62px 0px rgba(79, 136, 180, 0.3);
              transform: translateY(-10px);
            }
            &__top{
              display: flex;
              justify-content: space-between;
              padding-top: 8px;
              background: #F3F3F3;
              padding-bottom: 35px;
              &__left{
                width: 20%;
                img{
                  @media(max-width:867px){
                    width: 60px;
                    height: 60px;
                }
                }
              }
              &__right{
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 9px;
                &__title{
                  color: #282828;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 38.4px;
                }
                &__subtitle{
                  color: #282828;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 38.4px;
                }
                &__date{
                  color: #667785;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 34.56px;
                }
              }
            }
            &__image{
              overflow: hidden;
              height: 350px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 35px;
              @media(max-width:867px){
                height: 250px;
                img{
                width: 100%;
                height: 250px;
                }
            }
            }
            &__bot{
              display: flex;
              gap: 20px;
              align-items: center;
              margin-left: 20px;
              margin-bottom: 8px;
              &__comments{
                display: flex;
                gap: 8px;
                align-items: center;
                color: #911e31;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-style: normal;
                font-weight: 400;
                line-height: normal;   
              }
              &__likes{
                display: flex;
                gap: 8px;
                align-items: center;
                color: #911e31;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }

    }
</style>
