import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomePaginate from "vue-awesome-paginate";
import axios from 'axios' 

const axiosInstance = axios.create({ 
    baseURL: 'https://back-hongqi.mydev.kz/api/V1/', 
    params: { 
      lang: store.getters.getLang || 'ru' 
    } 
}) 

const app = createApp(App)
.use(store)
.use(VueAwesomePaginate)
.use(router)
app.config.globalProperties.$axios = { ...axiosInstance } 
app.config.globalProperties.$cdn = 'https://back-hongqi.mydev.kz/'
app.mount('#app')
