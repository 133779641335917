<template>
    <div class="news">
      <div class="desctop">
        <img src="@/assets/images/newsback.png" class="mainimg"/>
    </div>
    <div class="mobile">
        <img src="@/assets/images/newsbackmobile.jpg" class="mainimg1"/>
    </div>
        <div class="news__title container">
            НОВОСТИ
        </div>
        <div class="news__cards"  v-if="news.length">
            <div class="news__cards__card" v-for="item in news" :key="item">
                <div class="news__cards__card__image" @click="$router.push('/news/' + item.slug)">
                  <img :src="item.image"/>
                </div>
                <div class="news__cards__card__title">
                    {{ item.title }}
                </div>
                <div class="lineblock4"></div>
                <div class="news__cards__card__bot">
                    <div class="news__cards__card__bot__date">
                    {{ item.time }}
                    </div>
                    <div class="news__cards__card__bot__button">
                      <button @click="$router.push('/news/' + item.slug)">Подробнее</button>
                    </div>
                </div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
  async created() {
    await this.$axios.get(`news`)
    .then(res => {
      this.news = res.data[0]
    })
    .catch(err => {
    })
},
    data(){
        return{
          news:[],
            newscards:[
            {
          img: "events_1",
          title: "Шанхайская автомобильная выставка HONGQI | Коллекция моделей H9",
          date: "2023-06.29",
        },
        {
          img: "events_2",
          title: "Шанхайская автомобильная выставка HONGQI | яркие моменты моделей",
          date: "2023-04.19",
        },
        {
          img: "events_3",
          title: "Выставка автомобилей HONGQI в Шанхае  Коллекция концепт-каров",
          date: "2023-03.29",
        },
            ]
        }
    },
}
</script>
<style lang="scss" scoped>
.lineblock4{
  border: 1px solid black;
}
    .news{
        display: flex;
        flex-direction: column;
        gap: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.6741))), 55px);
        padding-bottom: 70px;
        font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
        @media(max-width:867px){
          gap: 20px;
        }
        .mainimg{
          padding-top: 120px;
          width: 100%;
          @media(max-width:1710px){
              padding-top: 80px;
            }
            @media(max-width:1400px){
              padding-top: 70px;
              object-fit: cover;
            }
        }
        .mainimg1{
            padding-top: 75px;
            width: 100%;
            object-fit: cover;
        }
        &__title{
            text-align: center;
          font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 1.6741))), 35px);
            color: #282828;
            @media(max-width:867px){
          font-size: 20px;
        }
        }
        &__cards{
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          padding: 20px;
          @media(max-width:867px){
            flex-direction: column;
          }
          &__card{
            width: 32%;
            display: flex;
            flex-direction: column;
            gap: 32px;

            @media(max-width:1710px){
              width: 31.5%;
            }
            @media(max-width:1100px){
                  width: 30%;
                  gap: 10px;
                }
            @media(max-width:867px){
              padding: 10px;
            width:100%;
            gap: 12px;
          }
            &__image{
              overflow: hidden;
              height: min(max(170px, calc(10.625rem + ((1vw - 10.24px) * 18.9732))), 340px);
              display: flex;
              align-items: center;
              justify-content: center;
              @media(max-width:867px){
              height: 350px;
            }
            @media(max-width:767px){
              height: 250px;
            }
              img{
                transition: all 0.5s ease;
                width: 100%;
                height: 100%;
              }
            }
            &__title{
              color: #333;
              font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.558))), 17px);
              font-style: normal;
              font-weight: 700;
              line-height: 38.4px;
              flex-grow: 1;
            }
            &__bot{
              display: flex;
              justify-content: space-between;
              align-items: center;
              &__date{
                color: #282828;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.4464))), 14px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              &__button{
                button{
                border: 3px solid #000;
                background: #FFF;
                transition: all 0.5s ease;
                &:hover{
                  background: #282828;
                  color: white;
                }
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.6786))), 69px);
                color: #282828;
                font-size: 15px;
                font-weight: 700;
                width: min(max(140px, calc(8.75rem + ((1vw - 10.24px) * 6.6964))), 200px);
                @media(max-width:867px){
                  height: 30px;
                  width: 130px;
                }
            }

        }
    }
}
        }
    }
              
</style>