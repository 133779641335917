<template>
    <div class="contact">
        <div class="desctop">
        <img src="@/assets/images/contactback.jpg" class="mainimg"/>
        </div>
        <div class="mobile"> 
            <img src="@/assets/images/contactbackmobile.jpg" class="mainimg1"/>
        </div>
        <div class="contact__title container">
            СВЯЗАТЬСЯ С НАМИ
        </div>
        <div class="contact__info container">
            <div class="contact__info__left">
                <img src="@/assets/images/infocar.webp"/>
            </div>
            <div class="contact__info__right">
                <p class="contant__info__right__title">ТОО "Allur Premium"</p>
                <div class="contact__info__right__address">
                    <p style="font-weight: 600;">АДРЕС: </p>
                    <p>Адрес: Мангилик Ел, 63а</p>
                </div>
                <div class="contact__info__right__email">
                    <p style="font-weight: 600;">Электронаая почта: </p>
                    <p>Info.hongqi.ast@allur.kz</p>
                </div>
            </div>
        </div>
        <div class="contact__title container">
            СВЯЗАТЬСЯ С НАМИ
        </div>
        <div class="contact__fill container">
            <div class="contact__fill__left">
                <div class="contact__fill__left__title">
                    HONGQI РАССМАТРИВАЕТ КАЖДОЕ ПРЕДЛОЖЕНИЕ ОТ ВАС.
                </div>
                <textarea placeholder="Оставьте ваше предлоение." v-model="contact.comment"></textarea>
                <div class="contact__fill__left__checkbox">
                    <input type="checkbox"/>
                    <p>Я СОГЛАСЕН С ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ</p>
                </div>
            </div>
            <div class="contact__fill__right">
                <div class="contact__fill__right__input" :class="{ 'has-error': isSubmitClicked && !contact.phone }">
                    <p>МОБИЛЬНЫЙ ТЕЛЕФОН</p>
                    <input type="text" placeholder="ВВЕДИТЕ ВАШ НОМЕР ТЕЛЕФОНА" v-mask="'+7 (###) ###-##-##'" v-model="contact.phone" required/>
                    <p class="error-message" v-if="isSubmitClicked && !contact.phone">Пожалуйста, введите номер телефона</p>
                </div>
                <div class="contact__fill__right__select" :class="{ 'has-error': isSubmitClicked && !contact.city }">
                    <p>ГОРОД</p>
                    <select v-model="contact.city" @change="handleCityChange" required>
                        <option value="" disabled>Выберите город</option>
                        <option v-for="option in cityOptions" :value="option.value">{{ option.label }}</option>
                    </select>
                    <p class="error-message" v-if="isSubmitClicked && !contact.city">Пожалуйста, выберите город</p>
                </div>
                <div class="contact__fill__right__input" style="width: 48%;" :class="{ 'has-error': isSubmitClicked && !contact.name }">
                    <p>ИМЯ</p>
                    <input type="text" placeholder="ВВЕДИТЕ ВАШЕ ИМЯ" v-model="contact.name" required/>
                    <p class="error-message" v-if="isSubmitClicked && !contact.name">Пожалуйста, введите ваше имя</p>
                </div>
                <div class="contact__fill__right__input">
                    <p>ЭЛЕКТРОННАЯ ПОЧТА</p>
                    <input type="text" placeholder="ВВЕДИТЕ ВАШУ ЭЛЕКТРОННУЮ ПОЧТУ"/>
                </div>
            </div>
        </div>
        <button @click="sendData(),isSubmitClicked = true" :disabled="isLoading || !isFormValid">
        <div v-if="isLoading == true">
            <div class="loader">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
        </div>
        <div v-else>
            ОТПРАВИТЬ ПРЕДЛОЖЕНИЕ
        </div>
        </button>
    </div>
</template>
<script>
import { mask } from "vue-the-mask";

import axios from "axios";
export default {
    directives: { mask },
    data(){
        return{
            isLoading: false,
            isSubmitClicked: true,
            city: null,
            contact: {
            name: '',
            city: '',
            phone: '',
            comment: ''
        },
        cityOptions: [
            { label: 'АЛМАТЫ', value: 16 },
            { label: 'АСТАНА', value: 14 }
        ]
        }
    },
    methods:{
        handleCityChange() {
        this.city = this.contact.city; // Предполагается, что вы объявили city в data
    },
        sendData(){
            if (this.isFormValid){
            this.isLoading = true;
            const contactData = {
                fields: {
                NAME: this.contact.name,
                PHONE: [
                    {
                    VALUE: this.contact.phone.replace(/[^0-9]/g, ""),
                    VALUE_TYPE: "WORK",
                    },
                ],
                },
            };
             axios
        .post(
          "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.contact.add.json",
          contactData
        )
        .then((response) => {
          if (response.status === 200) {
            // const newContactId = response.data.result;
            // console.log("New Contact ID:", newContactId);
            // this.$store.state.openOk = true;
            const contactData1 = {
              FIELDS: {
                CATEGORY_ID: 2,
                STAGE_ID: "C2:NEW",
                UF_CRM_1604656131: this.city,
                UF_CRM_1590070364: 1238,
                SOURCE_ID: "WEBFORM",
                UF_CRM_1613979771: 4064,
                UF_CRM_1634038284: "Hongqi.kz",
                UF_CRM_1586840541: 4140,
                UF_CRM_1611565554: '',
                UF_CRM_1586841138: 4006,
                UF_CRM_1657876982: 1210,
                CONTACT_ID: response.data.result,
                COMMENTS: this.contact.comment,
              },
            };
            axios
              .post(
                "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.deal.add.json",
                contactData1
              )
              .then((response) => {
                if (response.status === 200) {
                    this.$store.state.openOk = true;
                    this.isSubmitClicked = false;
                    this.isLoading = false;
                } 
                else {
                  console.log(response);
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                // this.isLoading = false;
              });
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          // this.isLoading = false;
        });
        }
    }
    },
    created () {
        if (this.contact.city === 'АСТАНА') {
            this.city = 14;
        }
        if (this.contact.city === 'АЛМАТЫ') {
            this.city = 16;
        }
    },
    computed: {
    isFormValid() {
        return this.contact.city && this.contact.name && this.contact.phone;
    }
}
}
</script>

<style lang="scss" scoped>

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
.has-error {
    input {
        border-color: #ff4f4f; /* Цвет границы при ошибке */
    }
    
    .error-message {
        color: #ff4f4f; /* Цвет текста ошибки */
        font-size: 14px;
        margin-top: 5px;
    }
}
.mainimg{
    width: 100%;
    padding-top: 75px;
    @media(max-width:1400px){
            padding-top: 70px;
    }
    @media(max-width:867px){
        padding-top: 55px;
        height: 20vh;
        object-fit: cover;
    }
}
.mainimg1{
            padding-top: 75px;
            width: 100%;
            object-fit: cover;
}
    .contact{
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding-bottom: 100px;
        @media(max-width:867px){
            gap: 20px;
        }
        &__title{
            text-align: center;
            color: #282828;
            font-size: 40px;
            @media(max-width:867px){
                font-size: 24px;
    }
        }
        &__info{
            display: flex;
            justify-content: center;
            @media(max-width:867px){
                flex-direction: column;
    }
            &__left{
                width: 40%;
                height: 648px;
                @media(max-width:867px){
                    width: 100%;
                    height: fit-content;
    }
                img{
                    width: 100%;
                    height: inherit;
                    object-fit: cover;
                }
            }
            &__right{
                background: #F3F3F3;
                width: 30%;
                padding: 140px 40px;
                display: flex;
                flex-direction: column;
                gap: 40px;
                color: #282828;
                @media(max-width:867px){
                    width: 100%;
                    padding: 20px;
                    gap: 20px;
                }
                &__address{
                    display: flex;
                    gap: 10px;
                }
                &__email{
                    display: flex;
                    gap: 10px;
                }
            }
        }
        &__fill{
            display: flex;
            justify-content: space-between;
            @media(max-width:867px){
                flex-direction: column;
                gap: 20px;
                padding: 10px;
            }
            &__left{
                width: 45%;
                display: flex;
                flex-direction: column;
                @media(max-width:867px){
                    width: 100%;
                }
                &__title{
                    font-size: 20px;
                    color: #939393;
                    padding-bottom: 15px;
                    @media(max-width:867px){
                        font-size: 14px;
                     }
                }
                textarea{
                    height: 300px;
                    width: 100%;
                    resize: none;
                    outline: none;
                    padding: 15px;
                    background-color: #e9e9e9;
                    @media(max-width:867px){
                        width: 100%;
    }
                }
                &__checkbox{
                    display: flex;
                    padding-top: 30px;
                    align-items: center;
                    font-size: 18px;
                    gap: 10px;
                    @media(max-width:867px){
                        font-size: 12px;
                    }
                }
            }
            &__right{
                width: 45%;
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
                @media(max-width:867px){
                    width: 100%;
                    font-size: 15px;
                }
                &__input{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    color: #8b8b8b;
                    font-size: 20px;
                    input{
                        height: 50px;
                        border-top: none; /* Убираем верхний бордер */
                        border-left: none; /* Убираем левый бордер */
                        border-right: none;
                        outline: none;
                        &::placeholder{
                            color: black;
                            font-size: 12px;
                        }
                    }
                }
                &__select{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    color: #8b8b8b;
                    font-size: 20px;
                    select{
                        appearance: none; /* Убираем стандартные стили браузера */
                        background: white;
                        color: black;
                        width: 100%;
                        padding: 15px 0px;
                        outline: none;
                        border-top: none; /* Убираем верхний бордер */
                        border-left: none; /* Убираем левый бордер */
                        border-right: none;
                        border-bottom: 1px solid black;
                    }
                }
            }
        }
        button{
            width: 20%;
            margin-right: auto;
            margin-left: auto;
            height: 60px;
            background-color: #cf1a1a;
            cursor: pointer;
            border: none;
            border-radius: 40px;
            color: white;
            @media(max-width:867px){
                width: 50%;
                height: 40px;
             }
        }
    }
</style>
