<template>
    <div class="dilers">
        <div class="dilers__title">
            Дилеры
        </div>
        <div class="dilers__info">
            <div class="dilers__info__left">
                <div class="dilers__info__left__1" @click="changeCity('Алматы')" :class="{ 'selected': selectedCity === 'Алматы' }">
                    <p class="dilers__info__left__1__title">Алматы Allur</p>
                    <div class="line"></div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">АДРЕС:</p>
                        <p class="dilers__info__left__1__input__subtitle">ул. Майлина 240А</p>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">Время работы:</p>
                        <p class="dilers__info__left__1__input__subtitle">Пн-Пт  9:00-21:00<br/>Сб-Вс   9:00-19:00</p>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">ТЕЛ: </p>
                        <p class="dilers__info__left__1__input__subtitle">5588</p>
                    </div>
                </div>
                <div class="dilers__info__left__2" @click="changeCity('Астана')" :class="{ 'selected': selectedCity === 'Астана' }"> 
                    <p class="dilers__info__left__2__title">Астана Allur</p>
                    <div class="line"></div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">АДРЕС:</p>
                        <p class="dilers__info__left__1__input__subtitle"> Мангилик Ел 63а</p>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">Время работы:</p>
                        <p class="dilers__info__left__1__input__subtitle">Пн-Вс 9:00-20:00</p>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">ТЕЛ: </p>
                        <p class="dilers__info__left__1__input__subtitle">5588</p>
                    </div>
                </div>
            </div>
            <div class="dilers__info__right" style="position: relative;">
                <div id="dilers-map-container" style="height:100%; width: 100%;"></div>
            </div>
        </div>
        <div class="dilers__bot">
            <img src="@/assets/images/dilerbot.jpg"/>
        </div>
            </div>
</template>
<script>
export default {
    data() {
        return {
            map: null,
            geocoder: null,
            marker: null,
            defaultCity: 'Алматы',
            defaultLatitude: 43.323693,
            defaultLongitude: 76.975496,
            astanaLatitude: 51.080568,
            astanaLongitude: 71.42064,
            selectedCity: 'Алматы',
        };
    },
    mounted() {
        this.load2GISAPI().then(() => {
            this.initMap();
        });
    },
    methods: {
        load2GISAPI() {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://maps.api.2gis.ru/2.0/loader.js?pkg=full';
                script.onload = resolve;
                document.head.appendChild(script);
            });
        },
        initMap() {
            const mapContainer = document.getElementById('dilers-map-container');
            
            // Инициализация карты 2GIS
            DG.then(() => {
                this.map = DG.map(mapContainer, {
                    center: [this.defaultLatitude, this.defaultLongitude],
                    zoom: 16
                });

                this.geocoder = DG.geocoding();

                this.setMarker(this.defaultCity);
            });
        },
        setMarker(city) {
            let coordinates;
            if (city === 'Алматы') {
                coordinates = [this.defaultLatitude, this.defaultLongitude];
            } else if (city === 'Астана') {
                coordinates = [this.astanaLatitude, this.astanaLongitude];
            }

            if (coordinates) {
                if (this.marker) {
                    this.marker.removeFrom(this.map);
                }
                this.map.setView(coordinates, 16); // Сначала меняем центрирование карты
                this.marker = DG.marker(coordinates).addTo(this.map); // Затем устанавливаем маркер
            }
        },
        changeCity(city) {
            this.selectedCity = city;
            this.setMarker(city);
        }
    }
};
</script>
<style lang="scss" scoped>
.selected {
  border: 4px solid red; // Применяем стиль с красным бордером для выбранного блока
}
.line{
    border: 1px solid black;
}
    .dilers{
        display: flex;
        flex-direction: column;
        padding-top: 150px;
        @media(max-width:867px){
                    padding-top: 100px;
                }
        &__title{
            text-align: center;
            font-size: 32px;
            color: #282828;
            padding-bottom: 50px;
            gap: 10px;
            text-transform: uppercase;
            @media(max-width:867px){
                    font-size: 24px;
                    padding-bottom: 20px;
                }
        }
        &__info{
            display: flex;
            padding: 10px 80px;
            justify-content: space-between;
            background: #f3f3f3;
            @media(max-width:867px){
                    padding: 30px;
                }
            &__left{
                display: flex;
                flex-direction: column;
                gap: 30px;
                width: 35%;
                @media(max-width:867px){
                    width: 100%;
                }
                &__1{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 16px 32px;
                    color: #282828;
                    background: white;
                    font-size: 30px;
                    cursor: pointer;
                    &__title{
                        @media(max-width:867px){
                            font-size: 20px;
                        }
                    }
                    &__input{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        &__title{
                            font-size: 20px;
                            font-weight: 600;
                            @media(max-width:867px){
                                font-size: 12px;
                                width: 40%;
                            }
                        }
                        &__subtitle{
                            font-size: 18px;
                            @media(max-width:867px){
                                font-size: 10px;
                                width: 55%;
                            }
                        }
                    }
                }
                &__2{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 16px 32px;
                    color: #282828;
                    background: white;
                    font-size: 30px;
                    cursor: pointer;
                    &__title{
                        @media(max-width:867px){
                            font-size: 20px;
                        }
                    }
                }
            }
            &__right{
                width: 64%;
                @media(max-width:867px){
                    display: none;
                }
            }
        }
        &__bot{
            img{
                width: 100%; 
                padding-bottom: 0px;
                @media(max-width:867px){
                    height: 13vh;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
</style>