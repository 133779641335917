<template>
  <div class="test">
    <div class="test__title">{{ modalTitle }}</div>
    <div class="test__inputs">
      <div class="input">
        <input
          v-model="testData.name"
          id="nameInput"
          placeholder="ИМЯ: *"
          type="text"
        />
        <span class="error-message">{{ fieldErrors.name }}</span>
      </div>
      <div class="select">
        <select v-model="testData.dealer" id="dealerSelect">
          <option value="" disabled selected>Дилер:</option>
          <option v-for="(item, idx) in dealer" :key="'d' + idx" :value="item">
            {{ item.name }}
          </option>
        </select>
        <span class="error-message">{{ fieldErrors.dealer }}</span>
      </div>
      <div class="input">
        <input
          v-model="testData.phone"
          v-mask="'+7 (###) ###-##-##'"
          id="phoneInput"
          placeholder="Моб.Телефон: +7"
          type="text"
        />
        <span class="error-message">{{ fieldErrors.phone }}</span>
      </div>
      <div class="select">
        <select v-model="testData.city" id="citySelect">
          <option value="" disabled selected>Выберите город:</option>
          <option v-for="(item, idx) in city" :key="'c' + idx" :value="item">
            {{ item.name }}
          </option>
        </select>
        <span class="error-message">{{ fieldErrors.city }}</span>
      </div>
    </div>
    <div class="test__bot">
      <input
        v-model="testData.agreement"
        id="privacyCheckbox"
        type="checkbox"
      />
      <p>
        <span class="test__bot__title"
          >Я СОГЛАСЕН С ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ</span
        ><br />Что касается личной информации, которую вы предоставляете, мы
        будем уважать вашу конфиденциальность и сохраним данную информацию в
        тайне.
      </p>

      <span class="error-message">{{ fieldErrors.agreement }}</span>
    </div>
    <button id="submitButton" @click="sendData" :disabled="isLoading">
      <div class="loader" v-if="isLoading">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
          <div v-else>ОТПРАВИТЬ</div>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
export default {
  props: {
    modalTitle: String, // Пропс для заголовка модального окна
  },
  directives: { mask },
  data: () => ({
    isLoading: false,
    currentPath: "",
    model: 1,
    newContactId: "",
    testData: {
      name: null,
      dealer: "",
      city: "",
      phone: "",
      agreement: false,
    },
    requiredFields: ["name", "dealer", "city", "phone", "agreement"], // список обязательных полей
    fieldErrors: {},
    city: [
      {
        id: 1,
        name: "Алматы",
      },
      {
        id: 2,
        name: "Астана",
      },
    ],
    dealer: [
      {
        id: 1,
        name: "Алматы Allur",
        route: 16,
      },
      {
        id: 2,
        name: "Астана Allur",
        route: 14,
      },
    ],
  }),
  methods: {
    sendData() {
      this.fieldErrors = {};
      let hasErrors = false;
      for (const field of this.requiredFields) {
        if (!this.testData[field]) {
          this.fieldErrors[field] = "Обязательное поле";
          hasErrors = true;
        }
      }
      this.isLoading = true;
      if (hasErrors) {
        return;
      }
      const article = {
        Name: this.testData?.name,
        Dealer: this.testData?.dealer?.name,
        Phone: this.testData?.phone.replace(/[^0-9]/g, ""),
        City: this.testData?.city?.name,
        Agreement: this.testData?.agreement,
        Model: this.$route.name,
        Date: Date(),
      };
      const contactData = {
        fields: {
          NAME: this.testData?.name,
          PHONE: [
            {
              VALUE: this.testData?.phone.replace(/[^0-9]/g, ""),
              VALUE_TYPE: "WORK",
            },
          ],
        },
      };

      axios
        .post(
          "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.contact.add.json",
          contactData
        )
        .then((response) => {
          if (response.status === 200) {
            // const newContactId = response.data.result;
            // console.log("New Contact ID:", newContactId);
            // this.$store.state.openOk = true;
            const contactData1 = {
              FIELDS: {
                CATEGORY_ID: 2,
                STAGE_ID: "C2:NEW",
                UF_CRM_1604656131: this.testData?.dealer?.route,
                UF_CRM_1590070364: 1238,
                SOURCE_ID: "WEBFORM",
                UF_CRM_1613979771: 4064,
                UF_CRM_1634038284: "Hongqi.kz",
                UF_CRM_1586840541: 4140,
                UF_CRM_1611565554: this.model,
                UF_CRM_1586841138: 4006,
                UF_CRM_1657876982: 1210,
                CONTACT_ID: response.data.result,
                COMMENTS: "Комментарий Комментарий",
              },
            };
            axios
              .post(
                "https://hongqi-allur.bitrix24.kz/rest/1/volowfdbjo6g04s0/crm.deal.add.json",
                contactData1
              )
              .then((response) => {
                if (response.status === 200) {
                  axios
                    .post(
                      "https://sheetdb.io/api/v1/098p87j5j1ygf?sheet=Model",
                      article
                    )
                    .then((response) => {
                      if (response.status === 201) {
                        this.$store.state.openOk = true;
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                } else {
                  console.log(response);
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                // this.isLoading = false;
              });
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.testData.name = '';
          this.testData.dealer = '';
          this.testData.phone = '';
          this.testData.city = '';
          this.testData.agreement = false;
        });
    },
  },
  created() {
    this.currentPath = this.$route.path;
    if (this.currentPath === "/h9") {
      this.model = 13962;
    }
    if (this.currentPath === "/h5") {
      this.model = 13960;
    }
    if (this.currentPath === "/hs5") {
      this.model = 13964;
    }
    if (this.currentPath === "/e-hs9") {
      this.model = 13966;
    }
  },
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.test {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 2.2321))), 50px) min(max(150px, calc(9.375rem + ((1vw - 10.24px) * 5.5804))), 200px);

  @media (max-width: 867px) {
    padding: 20px;
  }

  &__title {
    text-align: center;
    font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
    color: #000000;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    gap: min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);
    row-gap: 50px;

    .input {
      width: 41%;

      @media (max-width: 1710px) {
        width: 39%;
      }

      @media (max-width: 867px) {
        width: 95%;
        padding: 10px;
      }

      input {
        width: 100%;
        text-transform: uppercase;
        font-size: 14px;

        padding: 25px;
        outline: none;

        &::placeholder {
          color: black;
          font-size: 15px;
        }
      }
    }

    .select {
      width: 43%;

      @media (max-width: 1710px) {
        width: 39%;
      }

      @media (max-width: 867px) {
        width: 95%;
        padding: 10px;
      }

      select {
        width: 100%;
        padding: 25px;
        text-transform: uppercase;
        outline: none;
        border-radius: 5px;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.6696))), 18px);
        font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu,
          Cantarell, Noto Sans, sans-serif, STHeiti, Microsoft YaHei, Arial;
        border: 1px solid black;
        appearance: none;
        background: white;
        color: black;

        &::placeholder {
          color: black;
          font-size: 15px;
        }
      }
    }
  }

  &__bot {
    display: flex;
    gap: 20px;
    align-items: center;

    &__title {
      font-size: min(max(18px, calc(1.125rem + ((1vw - 10.24px) * 0.7813))), 25px);
      color: #6d6e6f;

      @media (max-width: 867px) {
        font-size: 12px !important;
      }
    }

    @media (max-width: 867px) {
      font-size: 12px !important;
      align-items: start;
    }
  }

  button {
    width: 25%;
    padding: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.558))), 15px);
    background-color: #42373c;
    border-radius: 30px;
    border: none;
    color: white;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    @media (max-width: 867px) {
      width: 50%;
    }
  }
}
</style>
