<template>
    <div class="world">
        <div class="desctop">
            <img src="@/assets/images/worldback.png" class="mainimg"/>
        </div>
        <div class="mobile">
            <img src="@/assets/images/worldbackmobile.jpg" class="mainimg1"/>
        </div>
            <router-link to="/history" style="text-decoration: none;">
                <div class="world__history container">
                <p>История</p>
                <button class="worldbutton">Подробности</button>
            </div>
            </router-link>
            <div class="desctop">
            <div class="world__brand container">
                <div class="world__brand__left">
                    <p>БРЕНД</p>
                    <router-link to="/brand" style="text-decoration: none;"><button class="worldbutton">Подробности</button></router-link>
                </div>
                <div class="world__brand__right">
                <img src="@/assets/images/brand.png"/>
            </div>
            </div>
            <div class="world__development container">
                <div class="world__development__left">
                    <img src="@/assets/images/worlddevelopment.png"/>
                </div>
                <div class="world__development__right">
                    <p>ИССЛЕДОВАНИЯ И РАЗРАБОТКИ</p>
                    <router-link to="/develop" style="text-decoration: none;"><button class="worldbutton">Подробности</button></router-link>
                </div>
            </div>
            <div class="world__achievements container">
                <div class="world__achievements__left">
                    <p>ДОСТИЖЕНИЯ</p>
                    <router-link to="/achievement" style="text-decoration: none;"><button class="worldbutton">Подробности</button></router-link>
                </div>
                <div class="world__achievements__right">
                    <img src="@/assets/images/worldachievments.png"/>
                </div>
            </div>
            <div class="world__social container">
                <div class="world__social__left">
                    <img src="@/assets/images/worldsocial.png"/>
                </div>
                <div class="world__social__right">
                    <p>СОЦИАЛЬНАЯ ОТВЕТСТВЕННОСТЬ</p>
                    <router-link to="/social" style="text-decoration: none;"><button class="worldbutton">Подробности</button></router-link>
                </div>
            </div>
        </div>
        <div class="mobile">
            <router-link to="/brand" style="text-decoration: none; color: inherit;"><div class="brandmobile container">
                <p class="brandmobile__text">БРЕНД</p>
            </div></router-link>
            <router-link to="/develop" style="text-decoration: none; color: inherit;"><div class="developmentmobile container">
                    <p class="developmentmobile__text">ИССЛЕДОВАНИЯ И РАЗРАБОТКИ</p>
            </div></router-link>
            <router-link to="/achievement" style="text-decoration: none; color: inherit;"><div class="achievementsmobile container">
                    <p class="achievementsmobile__text">ДОСТИЖЕНИЯ</p>
            </div></router-link>
            <router-link to="/social" style="text-decoration: none; color: inherit;"><div class="socialmobile container">
                    <p class="socialmobile__text">СОЦИАЛЬНАЯ ОТВЕТСТВЕННОСТЬ</p>
            </div></router-link>
        </div>
</div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.mainimg{
          padding-top: 120px;
          width: 100%;
          @media(max-width:1710px){
              padding-top: 80px;
            }
            @media(max-width:1400px){
                padding-top: 70px;
                object-fit: cover;
            }
        }
        .mainimg1{
            padding-top: 75px;
            width: 100%;
            object-fit: cover;
        }
.worldbutton{
    width: min(max(150px, calc(9.375rem + ((1vw - 10.24px) * 12.7232))), 264px);

    height: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 3.125))), 58px);
    border-radius: 38.4px;
    background: #FAFCFB;
    color: #1D1D1D;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
    border: none;
    font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
    text-transform: uppercase;
    @media(max-width:867px){
        display: none;
    }
}
.achievementsmobile{
    background-image: url('@/assets/images/worldachievments.png');
    height:300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;
    &::after { /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0; /* Set z-index to ensure the overlay appears below the text */
    }
    &__text {
        z-index: 1; /* Set z-index to ensure the text appears above the overlay */
    }
}
.developmentmobile{
    background-image: url('@/assets/images/worlddevelopment.png');
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: cover;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;
    &::after { /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0; /* Set z-index to ensure the overlay appears below the text */
    }
    &__text {
        z-index: 1; /* Set z-index to ensure the text appears above the overlay */
    }
}
.brandmobile {
    background-image: url('@/assets/images/brand.png');
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    object-fit: cover;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;
    &::after { /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0; /* Set z-index to ensure the overlay appears below the text */
    }
    &__text {
        z-index: 1; /* Set z-index to ensure the text appears above the overlay */
    }
}
.socialmobile{
    background-image: url('@/assets/images/worldsocial.png');
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    object-fit: cover;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;
    &::after { /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0; /* Set z-index to ensure the overlay appears below the text */
    }
    &__text {
        z-index: 1; /* Set z-index to ensure the text appears above the overlay */
    }
}
    .world{
        display: flex;
        flex-direction: column;
        gap: 77px;
        padding-bottom: 100px;
        @media(max-width:867px){
                gap:10px;
                padding-bottom: 0px;
            }
        &__history{
            background-image: url('@/assets/images/worldhistoryback.png');
            height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;
            color: #FFF;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 10.24px) * 1.3839))), 30.4px);
            @media(max-width:867px){
                height: 270px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                font-size: 24.4px;
            }
        }
        &__brand{
            display: flex;
            &__left{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50%;
                background: #E8E8E8;
                color: #282828;
                gap: 50px;
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
                height:min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
            }
            &__right{
                width: 50%;
                img{
                    width: 100%;
                    height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
                }
            }

        }
        &__development{
            display: flex;
            &__right{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50%;
                background: #E8E8E8;
                color: #282828;
                gap: 50px;
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
                height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);

            }
            &__left{
                width: 50%;
                img{
                    width: 100%;
                height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
                }
            }
        }
        &__achievements{
            display: flex;
            &__left{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50%;
                background: #E8E8E8;
                color: #282828;
                gap: 50px;
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
                height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
            }
            &__right{
                width: 50%;
                img{
                    width: 100%;
                height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
                }
            }
        }
        &__social{
            display: flex;
            &__right{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50%;
                background: #E8E8E8;
                color: #282828;
                gap: 50px;
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
                height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);

            }
            &__left{
                width: 50%;
                img{
                    width: 100%;
                height: min(max(450px, calc(28.125rem + ((1vw - 10.24px) * 31.9196))), 736px);
                }
            }
        }
    }
</style>