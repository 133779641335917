<template>
    <img src="@/assets/images/history/banner.jpg" class="mainimg"/>
    <div class="history container">
        <div class="history__title">
            <p>История HONGQI</p>
        </div>
        <div class="history__cards">
            <div :class="{'cardreverse': index % 2 !== 0}" class="history__cards__card" v-for="(item,index) in cards" :key="index">
                <div class="history__cards__card__img">
                    <img :src="require('@/assets/images/history/' + item.img + '.png')"/>
                </div>
                <div class="history__cards__card__info" :class="{'cardreverse': index % 2 !== 0}">
                    <p class="history__cards__card__info__year">{{ item.year }}</p>
                    <p style="font-size: 24px;">HONGQ<br/>ИСТОРИЯ</p>
                    <p class="history__cards__card__info__text">{{ item.text}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            cards: [
                {
                    img:"1",
                    year:"1958",
                    text:"В 1958 году появился первый прототип марки Hongqi, который также стал первым легковым автомобилем, собранным и выпущенным в Китае."
                },
                {
                    img:"2",
                    year:"1959",
                    text:"В августе, после усовершенствования дизайна и пяти этапов пробного производства, Hongqi запустили серийный выпуск автомобиля, присвоив ему номер CA72."
                },
                {
                    img:"3",
                    year:"1965",
                    text:"Седан CA770 Hongqi с трехрядным салоном также успешно поставили на конвейер. Его часто называют 'национальным автомобилем' или 'первым китайским автомобилем'. Он известен по всему миру как транспортное средство китайских лидеров и иностранных гостей высшего ранга. Эта модель производилась серийно в 1960-х и 1970-х годах в двух разных конфигурациях: CA770A и CA770B."
                },
                {
                    img:"4",
                    year:"1967",
                    text:"Hongqi разработали новый двухрядный седан серии CA770 - старшую модель CA771."
                },
                {
                    img:"5",
                    year:"1968",
                    text:"В рамках расширения линейки автомобилей Hongqi на базе упрощенной трехрядной версии серии CA770 - разработан усовершенствованный седан CA773."
                },
                {
                    img:"6",
                    year:"1969",
                    text:"В течение следующих трех лет Hongqi успешно собрали и провели испытания первого автомобиля для служб безопасности. На снимке представлен специальный бронированный автомобиль Hongqi CA772."
                },
                {
                    img:"7",
                    year:"1970",
                    text:"Обзор Hongqi CA770J. Кузов без металлической крыши, заднее сиденье открытого типа, спинка поворачивается и откидывается, образуя более высокое сиденье с более широким углом обзора. Центральная перегородка оснащена высокими поручнями, за которые удобно держаться пассажиру, и стеклянной панелью для защиты от ветра и дождя во время парада."
                },
                {
                    img:"8",
                    year:"1980",
                    text:"Стараясь идти в ногу с передовыми достижениями в области автомобилестроения того времени, Hongqi сконцентрировалась на внедрении новых конструкций и технологий, направленных на снижение энергопотребления, повышении мощности и комфорта, улучшение внешнего вида. Результатом работы стал модернизированный седан СА774, пришедший на замену устаревшей модели CA770."
                },
                {
                    img:"9",
                    year:"1980",
                    text:"Туристический автобус Hongqi CA630 - небольшой 19-местный пассажирский автобус, использовавшийся гостиницами, турагентствами и правительственными организациями."
                },
                {
                    img:"10",
                    year:"1984",
                    text:"Завершается производство седана среднего класса СА750. Покупатели хорошо встретили новый автомобиль. Позднее на базе этой модели появляется СА750F, не имеющий абсолютно никаких внешних отличий, но ставший первым переднеприводным седаном, который Китай разработал самостоятельно."
                },
                {
                    img:"11",
                    year:"1985",
                    text:"Старшая модель в линейке - седан Hongqi CA770G - успешно прошел испытания и получил хорошие отзывы."
                },
                {
                    img:"12",
                    year:"1998",
                    text:"Совместно с американской компанией Ford Motor Company компания Hongqi запускает производство нового флагманского седана класса люкс - CA7460. Модель представляет собой идеальное сочетание культуры востока и современных технологий, дарящее ощущение близости и очарования новой эпохи. Модель поступила в серийное производство и вызвала небывалый интерес у публики."
                },
                {
                    img:"13",
                    year:"2000",
                    text:"В начале 1990-х годов сошла с конвейера одна из первых моделей нового поколения седанов Hongqi CA7202E3. В 2000 году, после нескольких этапов пробного производства и улучшения дизайна, данная модель была официально запущена в серийное производство. Позднее, в 2002 году, на базе CA7202E3 выпустили седан CA7242E6 с новым двигателем V5 объемом 2,4 л., который стал отличной заменой старой модели."
                },
                {
                    img:"14",
                    year:"2013",
                    text:"Публике представлен премиальный седан нового поколения Hongqi H7."
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.mainimg{
    padding-top: 80px;
    @media(max-width:867px){
        padding-top: 55px;
        height: 30vh;
    }
}
.cardreverse{
    display: flex;
    flex-direction: row-reverse;
    text-align: start !important;
}
    .history{
        display: flex;
        flex-direction: column;
        gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 1.5625))), 50px);
        padding-top: 50px;
        padding-bottom: 50px;
        &__title{
            text-align: center;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
            color: #282828;
            font-weight: 500;
        }
        &__cards{
            display: flex;
            flex-direction: column;
            gap: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 1.5625))), 50px);
            &__card{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media(max-width:867px){
                 flex-direction: column;
                }
                &__img{
                    width: 50%;
                    @media(max-width:867px){
                        width: 90%;
                    }
                    img{
                        width: 100%;
                    }
                }
                &__info{
                    width: 45%;
                    @media(max-width:867px){
                        width: 90%;
                    }
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    text-align: end;
                    @media(max-width:867px){
                        gap: 20px;
                    }
                    &__year{
                        font-size: min(max(54px, calc(3.375rem + ((1vw - 10.24px) * 4.6875))), 96px);
                        color: #333333;
                        @media(max-width:867px){
                            font-size: 48px;
                        }
                        
                    }
                    &__text{
                        font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                        color: #282828;
                        @media(max-width:867px){
                            font-size: 14px;
            }
                    }
                }
            }
        }
    }
</style>